@import '../../assets/scss/variables.scss';
.drawer{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background-color: $color-white;
    width: 100%;
    max-width: 50%;
    box-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
    padding: 15px;
    transition: all 0.2s;
    z-index: 9;
    &.fullSize{
        max-width: 100%;
        @include max-screen($mobile) {
            position: absolute;
            padding: 15px;
                    height: 100%;
        }
    }
    @include max-screen($mobile) {
        max-width: 100%;
        position: static;
        height: auto;
        box-shadow: 0 0 0;
    }
    &__resize{
        span{
            width: 24px;
            height: 24px;
        }
    }
   &.fullSizeMobile{
    @include max-screen($mobile) {
        max-width: 100%;
        position: absolute;
        height: 100%;
    }
    .drawer__resize{
        @include max-screen($mobile) {
         visibility: hidden;
        }
    }
   }
}