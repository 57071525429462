.custom-grid{
    &:hover .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb{
        background-color:  $blue3;
      }

    .MuiDataGrid-root{
        border-left: 0 !important;
        border-right: 0 !important;
        border-bottom: 0 !important;
        border-radius: 0;
        .MuiDataGrid-cell {
            font-size: 14px;
            font-family: $font-primary;
            color: $color-black;
    
        &:not(:last-child){
            border-right: 1px solid $color-gray;
        }
        }
        .MuiDataGrid-withBorderColor{
            border-color: $color-gray;
        }
        .MuiDataGrid-columnHeaderTitle{
            font-size: 14px;
            font-weight: 500;
            color: $color-green-5;
            font-family: $font-primary;
            gap: 8px;
        }
        .MuiDataGrid-columnHeader{
            &:not(:last-child){
                border-right: 1px solid $color-gray;
            }
  
        }
        .MuiDataGrid-columnHeader--last{
            border-right: 0 !important;
        }
        .text-gray{
            color: $color-green-5;
        }
        .MuiDataGrid-footerContainer{
            min-height: 38px;
        }

    }
    .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus{
        outline: solid transparent !important;
    }
    .MuiDataGrid-footerContainer {
        border: 0;
    }
    .MuiDataGrid-filler {
        --rowBorderColor: transparent !important;
    }
}
.customCheckBox{
    label{
        span{
            position: relative;
            width: 14px;
            height: 14px;
            display: block;
            // cursor: pointer;
            &::before{
                        content: "";
                      background-color: $color-gray;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 14px;
                     height: 14px;
                    }
        }
        input{
            position: absolute;
            width: 14px;
            height: 14px;
            opacity: 0;
            &:checked{
                &+span{
                    &::before{
                        background-color: $color-white;
                        background-image: url('../../assets/images/check-square.svg');
                        background-size: cover;
                    }
                }
            }
        }
     
    }
    .sticky-first {
        position: sticky;
        left: 0;
        background-color: $color-white;
        z-index: 3;
        width: 50px !important;
    }
}
.MuiDataGrid-columnHeaderTitle.customCheckBox.flex {
    display: flex;
    padding: 0 10px;
}
