@import '../../assets/scss/variables.scss';
.customGroup{
    width: 100%;
    gap: 0 24px;
    height: 100%;
    padding-bottom: 30px;
    &__card{
    width: calc(50% - 12px);
    @include max-screen($desktop) {
       width: 100%;
       margin-bottom: 15px;
    }
    }
    &__table{
        width: 100%;
        height: 100%;
         table{
            width: auto;
            tr{
                th{
                    min-width: 200px;
                    width: 200px;
                }
            }
         }
    }
    &__noData{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}