
.flex  {
    display: flex;
}
.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}
.flex-column {
    display: flex;
    flex-direction: column;
}
.align-start {
    align-items: flex-start;   
}
.align-center {
    align-items: center;
}
.align-end {
    align-items: flex-end;
}
.justify-between {
    justify-content: space-between;
}
.justify-around {
    justify-content: space-around;
}
.justify-center {
    justify-content: center;
}
.justify-end {
    justify-content: end;
}
.gap-20{
    gap:20px;
}
.gap-10{
    gap:10px;
}
.gap-4{
    gap:4px;
}
.inline-flex{
    display: inline-flex;
}