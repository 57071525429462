
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&display=swap');
@font-face {
    font-family: 'SeriouslyNostalgic Fn';
    src: url('../fonts/SeriouslyNostalgicFn-SemiCond.eot');
    src: url('../fonts/SeriouslyNostalgicFn-SemiCond.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SeriouslyNostalgicFn-SemiCond.woff2') format('woff2'),
        url('../fonts/SeriouslyNostalgicFn-SemiCond.woff') format('woff'),
        url('../fonts/SeriouslyNostalgicFn-SemiCond.ttf') format('truetype'),
        url('../fonts/SeriouslyNostalgicFn-SemiCond.svg#SeriouslyNostalgicFn-SemiCond') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Regular.eot');
    src: url('../fonts/Switzer-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Switzer-Regular.woff2') format('woff2'),
        url('../fonts/Switzer-Regular.woff') format('woff'),
        url('../fonts/Switzer-Regular.ttf') format('truetype'),
        url('../fonts/Switzer-Regular.svg#Switzer-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@import './variables.scss';
@import './base';
@import './flex';
@import './button';
@import './radio';
@import './input';
@import './select';
@import './table';
@import './switch';
@import './dialog';
@import './authStyle';
@import './matt';
@import './dataGrid';
@import './progressbar';
