@import 'assets/scss/variables.scss';
.listMain{
    width: 100%;
    padding-bottom: 30px;
    overflow-x: hidden;
    position: relative;
    // max-height: calc(100vh - 103px);
    // overflow-y: auto;
    &__wrapper{
        padding: 0 30px;
        .right, .left{
            width: 100%;
        }
        .left{
            margin-top: 5px;
            .box{
                width: calc(50% - 10px);
                @include max-screen($mobile) {
                    width: calc(100% - 10px)!important;
                }
            } 
        }
    }
    &__cardMain{
        width: 100%;
        :global .cardBox{
            width: 33.3%;
            padding: 0 15px;
            margin-bottom: 120px;
            @include max-screen($tablet) {
                width: 50%;
                margin-bottom: 30px;
            }
            @include max-screen($mobile) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
    .box{
        border: 1px solid $color-border;
        border-radius: 4px;
        width: calc(33.3% - 10px);
        margin: 10px 5px;
        overflow: hidden;
        @include max-screen($desktop) {
            width: calc(50% - 10px)!important;
        }
        @include max-screen($mobile) {
            width: calc(100% - 10px)!important;
        }
        header{
            padding: 15px;
            border-bottom: 1px solid $color-border;
            background-color: $color-primary;
            
            h2{
                font-size: 14px;
                color: $color-white;
                display: flex;
                align-items: center;
                svg{
                    margin-right: 5px;
                }
            }
        }
        article{
            padding: 15px;
            text-align: center;
            h4{
                font-size: 40px;
            }
        }
        &.fullWidth{
            width: calc(100% - 10px);
        }
    }
    .emailMain{
        max-height: 350px;
        overflow-y: auto;
        &:hover::-webkit-scrollbar-thumb{
            background-color:  $blue3;
          }
        &::-webkit-scrollbar {
            width: 7px;
            border-radius: 10px;

          }
          &::-webkit-scrollbar-thumb {
            background-color: $color-skeleton;
            border-radius: 10px;
          }
        .emailList{
            &__list{
                position: relative;
                margin: 32px 0;
                &::after{
                    content: "";
                    width: 10px;
                    height: 10px;
                    background-color: $color-green-2;
                    position: absolute;
                    right: 5px;
                    display: block;
                    top: 6px;
                    border-radius: 100%;
                }
           p{
            color: $color-label;
            font-weight: 500;
            font-size: 14px;
           
           }
            }
           
        }
    }
         .datePicker {
             padding: 30px 30px 15px;
             display: flex;
             justify-content: flex-end;
            :global .MuiFormControl-root {
                max-width: 300px;
                @include max-screen($mobile) {
                    max-width: 100% !important;
                }
            }
         }
   .chart{
    :global div{
width: 100%!important;
    }
   }
   
   
}
.main{
    padding: 24px 50px;
    @include min-screen( $desktop-x-large) {
        padding: 24px 150px;
        max-width: 1440px;
        margin: 0 auto;
    }
   
    @include max-screen($desktop) {
        padding: 15px;
    }
}
.dateSelect{
    margin-bottom: 35px;
ul{
    display: inline-flex;
justify-content: center;
align-items: center;
border: 1px solid $color-border;
border-radius: 4px;
overflow: hidden;
li{
    padding: 10px 16px;
    font-size: 14px;
font-style: normal;
font-weight: 600;
color: $color-label;
cursor: pointer;
    &:not(:last-child){
        border-right: 1px solid $color-border; 
    }
    &.active{
        background-color: rgba(89, 50, 243, 0.1);
    }
}
}

}
.cardFull{
    width: 100%;
}
.cardList{
    margin: 24px 0 40px;
    width: 100%;
    @include max-screen($mobile) {
        margin: 0;
       
    }
    .card{
        width: 33.3%;
        padding: 0 15px;
        @include max-screen($tablet) {
            width: 50%;
            margin-bottom: 24px;
        }
        @include max-screen($mobile) {
            width:100%;
            padding: 0
        }
    }
    :global {
        .noData{
      align-items: flex-start;
      text-align: left;
        }
    }
}
.graphList{
    margin: 30px -12px 0;
 .leftBox{
    width: 50%;
    padding: 0 12px;
    @include max-screen($tablet) {
        width: 100%;
        margin-bottom: 24px;
    }
 }
}
.graphBox{
    border: 1px solid $blue6;
    border-radius: 12px;
    width: 100%; 
    padding: 32px;
    height: 100%;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    header{
        border-bottom: 1px solid $color-border;
        padding-bottom: 15px;
        margin-bottom: 15px;
        h2{
            font-size: 20px;
            font-weight: 500;
            color:$color-blackLight;
        }
        p{
        font-size: 14px;
        font-weight: 400;
        color:$color-gray-dark;
       }
    }
}
.mapMain{
    padding: 24px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    border-radius: 12px;
    border: 1px solid $blue6;
    margin: 40px 0;
    header{
        width: 100%;
        padding-bottom: 20px;
        gap: 10px;
        border-bottom: 1px solid $color-border;
        margin-bottom: 20px;
        h2{
            font-size: 18px;
            font-weight: 600;
            color:$color-blackLight;
        }
        span{
            font-size: 14px;
            font-weight: 600;
            color:$color-gray-dark;
            border: 1px solid $color-border;
            padding: 10px 16px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            border-radius: 8px;

        }
    }
    .map{
        width: 60%;
        @include max-screen($tablet) {
            width: 100%;
        }
    }
    .mapList{
        width: 40%;
        padding-left: 64px;
        @include max-screen($tablet) {
            width: 100%;
            padding-left: 0;
            margin-top: 15px;
        }
        h2{
            font-size: 36px;
            font-weight: 600;
            color:$color-blackLight;
            margin-bottom: 20px;
        }
    }
}