@import '../../assets/scss/variables.scss';

.measurement {
    height: 100%;
position: relative;
    @include max-screen($tablet) {
        flex-direction: column-reverse;
        height: auto;
    }

    &__left {
        width: 50%;
       

        @include max-screen($tablet) {
            padding: 15px;
            width: 100%;
            margin-top: 15px;
        }
    }

    &__right {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include max-screen($tablet) {
            width: 100%;

        }

        img {
            width: auto;
            object-fit: cover;
            height: 100%;

            @include max-screen($tablet) {
                height: auto;
                width: auto;
                object-fit: contain;
            }
        }
    }

    &__inner {
        flex: 1;
        justify-content: center;
        padding: 15px 55px 15px 55px;
        @include max-screen($tablet) {
            padding: 15px;
        }
    }
    &__back{
        padding: 15px;
        position: absolute;
        @include max-screen($tablet) {
            position: static;
            order: 1;
        }
    }

    &__innerMain {
        width: 100%;
        max-width: 493px;
        h2 {
            font-size: 18px;
            font-weight: 500;
            color: $color-black-dark;
        }
    }

    &__innerMainBox {
        gap: 13px;
     
        margin: 30px auto 0;
    }

    &__btn {
        margin-top: 15px;

        span {
            font-size: 12px;
            color: $color-primary;
            text-decoration: underline;
        }
    }





    &__arrows {
        margin-top: 28px;
        gap: 10px;
    }
}