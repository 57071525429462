@import '../../assets/scss/variables.scss';
.upload{
    background-color: $color-white;
    border-radius: 16px;
    padding: 16px;
    width: 100%;
    text-align: center;
    border: 1px solid $color-gray-light;
    position: relative;
    max-width: 512px;
    cursor: pointer;
    input{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
    }
    &__info{
        width: 100%;
        img{
            display: table;
            margin: 0 auto 12px;
        }
       
h2{
    font-size: 14px;
    color: $color-gray-dark;
    font-weight: normal;
    cursor: pointer;
    span{
        font-weight: bold;
        color: $color-primary;
    }
}
p{
    font-size: 12px;
    color: $color-gray-dark;
    margin-top: 4px;
    cursor: pointer;
}
    }
}
.uploadFileInfo{
    display: flex;
    padding: 8px 10px;
    border-radius: 5px;
    margin: 6px 0;
    font-size: 12px;
    font-weight: 500;
    align-items: center;
    justify-content: space-between;
    color: $color-black-dark;
    background-color: rgba($color-gray-light, 0.3);
    border: 1px solid $color-gray-light;
    span {
        text-align: left;
        color: $color-gray-dark !important;
        width: 170px !important;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    svg {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
}