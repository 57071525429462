@import '../../assets/scss/variables.scss';

.sizeOnboard {
    height: 100%;

    form {
        flex: 1;
        height: 100%;

    }

    &__note {
        max-width: 476px;
    }

    &__form {
        overflow: auto;
        padding: 0 30px;
        margin-top: 41px;
        flex: 1;

        &:hover::-webkit-scrollbar-thumb {
            background-color: $blue3;
        }

        @include max-screen($mobile) {
            padding: 0;
        }
    }

    &__scroll {
        height: 100%;
        overflow-y: auto;

        &:hover::-webkit-scrollbar-thumb {
            background-color: $blue3;
        }
    }

    &__formGroup {
        &:not(:first-child) {
            margin-top: 51px;
        }

        h5 {
            font-size: 14px;
            font-weight: 500;
            color: $color-black-light;
        }

        p {
            font-size: 14px;
            color: $color-black-light;
            display: flex;
            gap: 8px;
            align-items: center;
        }
    }

    &__formGroupList {
        margin-top: 12px;
        margin-top: 12px;
        display: flex;
        gap: 8px;
        align-items: center;
        max-width: 314px;
    }

    &__formSpace {
        margin-top: 118px;
    }


    &__leftBtn {
        padding-top: 15px;
        padding-right: 85px;
        padding-bottom: 15px;

        @include max-screen($tablet) {
            padding-right: 0;
        }
    }

    &__parsing {
        box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
        border-radius: 10px;
        margin: 0 auto;
        padding: 30px;
        width: 100%;
        max-width: 616px;

    }

    &__parsingHeader {
        text-align: center;

        img {
            display: table;
            margin: 0 auto 10px;
        }

        h2 {
            font-size: 16px;
            font-weight: 600;
            color: $color-black-dark;
        }

        p {
            font-size: 14px;
            color: $color-gray-dark;
            margin-top: 14px;
        }
    }

    &__parsingRadio {
        border-radius: 8px;
        background-color: $color-green-7;
        margin: 19px auto 14px;
        padding: 12px;
        gap: 15px;
    }

    &__parsingWrapper {
        max-width: 423px;
        margin: 0 auto;

        textarea {
            font-size: 12px;
            height: 90px;
            resize: none;
        }
    }

    &__tables {
        max-width: 616px;
        margin: 73px auto 0;

        table {
            th {
                color: $color-black !important;
            }
        }
    }

}

.sizeOnboardTable {
    margin-bottom: 40px;

    h2 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    :global .table-input {
        text-align: center;

        input {
            text-align: center;
        }
    }
}

.batchUpload {
    padding: 45px 45px 21px 45px;
    position: relative;

    @include max-screen($tablet) {
        padding: 15px;
    }
&__close{
position: absolute;
right: 15px;
top: 15px;
}
    &__header {
        h3 {
            margin-top: 14px;
            font-size: 18px;
            font-weight: 600;
            color: $color-black-dark;
        }

        p {
            margin-top: 12px;
            color: $color-gray-dark;
            font-size: 14px;
        }
    }

    &__Uploaded {
        margin-top: 12px;

        p {

            color: $color-gray-dark;
            font-size: 14px;
        }
    }

    &__UploadedBar {
        margin: 50px 0;
    }

    &__Upload {
        margin-top: 41px;

    }

    &__Btn {
        margin-top: 41px;
        text-align: center;
    }

    &__file {
        display: flex;
        padding: 8px 10px;
        border-radius: 5px;
        margin: 6px 0;
        font-size: 12px;
        font-weight: 500;
        align-items: center;
        justify-content: space-between;
        color: $color-black-dark;
        background-color: rgba($color-gray-light, 0.3);
        border: 1px solid $color-gray-light;
        span {
            text-align: left;
            color: $color-gray-dark;
            width: calc(100% - 25px);
        }
        svg {
            width: 15px;
            height: 15px;
            cursor: pointer;
        }
    }
}