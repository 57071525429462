@import '../../assets/scss/variables.scss';
.cardContainer{
    width: 100%;
    // box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid $color-gray;
    padding:20px 30px ;
    margin-bottom: 30px;
    @include max-screen($tablet) {
        padding:10px ;
    }
    h2{
        font-size: 18px;
        font-weight: 500;
        color: $color-black;
        margin-bottom: 25px;
    }
    :global{
        .noData p {
            padding: 10px 0;
        }
    }
}