@import '../../assets/scss/variables.scss';
.sizing{
    padding: 15px;
    max-width: 1086px;
    margin: 0 auto;
 
    @include max-screen($tablet) {
        padding: 15px;
         }
    &__col{
        width: 33.3%;
        padding: 0 12.5px;
        @include max-screen($mobile) {
            width: 100%;
            margin-bottom: 15px;
             }
    }
}
.sizingMain{
    flex: 1;
}
.sizingBack{
    width: 100%;
    padding: 15px;
}