.input{
    label{
        font-size: 14px;
        font-weight: 500;
        color: $color-black-light;
        display: block;
        margin-bottom: 6px;
    }
    input{
        width: 100%;
        border-radius: 8px;
        border: 1px solid $color-border;
        padding: 10px 14px;
        height: 44px;
        color: $color-black;
        &::placeholder{
            color: $color-black-4;
        }
    }
    &__inner{
        position: relative;
    }
    &__prefix{
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-black-4;
     
    }
    &.prefix{
        input{
            padding-left: 42px;
        }
    }
}
.search{
    input{
        width: 100%;
        border-radius: 5px;
        border: 1px solid $color-border;
        padding: 8px 10px 8px 38px;
        height: 35px;
        color: $color-black;
        font-size: 14px;
        font-weight: 500;
        &::placeholder{
            color: $color-green-5;
        }
    }
    &__inner{
        position: relative;
    }
    &__prefix{
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-black-4;
     
    }
}