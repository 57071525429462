@import 'assets/scss/variables.scss';

.analyticsCard {
    height: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 0;

    h4 {
        font-size: 12px;
        font-weight: 500;
        color: $color-blackLight;
        display: inline-flex;
        align-items: center;
        gap: 8px;
    }

    .infoIcon {
        width: 15px;

        img {
            width: 15px;
            height: 15px;
        }
    }

    .percentValue {
        display: inline-flex;
        align-items: center;
        background-color: $successBg;
        height: 16px;
        padding: 2px 8px 2px 6px;
        gap: 4px;
        border-radius: 16px;
        font-size: 12px;
        color: $success;

        img {
            width: 12px;
            height: 12px;
        }

        &.redColor {
            background-color: $color-redBg;
            color: $color-red;
        }
    }

    h3 {
        font-size: 18px;
        font-weight: 500;
        color: $color-primary;
    }
    &.analyticsCardCustom{
        padding: 24px;
        border: 1px solid $blue6;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
        border-radius: 12px;
        background-color: $color-white;
      gap: 8px;
      height: 100%;
      margin-bottom: 8px;
      h3{
        font-size: 30px;
        color: $color-black-dark;
        font-weight: 600;
        margin: 15px 0;
     }
     h4{
        color: $color-black-dark;
    font-size: 16px;
    font-weight: 500;
     }
    }
}