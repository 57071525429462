.custom-radio {
    position: relative;
    font-family: $font-primary;
    margin: 2px 0;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
        span{
            font-weight: 400;
        }
    input[type="radio"] {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;

        &:checked {
            &+.custom-radio__box {
                outline: 2px solid $color-primary;
                background-color: $color-primary-light;

                h5,
                p {
                    color: $color-primary;
                }

                span {
                    background-color: $color-primary;
                   

                    svg {
                        opacity: 1;
                        color: $color-white;
                    }
                }
            }
            & +  span{
                font-weight: 500;
            }
        }

    }

    &__box {
        border-radius: 12px;
        cursor: pointer;
        border: 1px solid $color-gray-light;
        padding: 16px;
        padding-right: 50px;
        position: relative;

        h5 {
            font-size: 14px;
            font-weight: 500;
            color: $color-black-light;
        }

        p {
            font-size: 14px;
            color: $color-gray-dark;
            margin-top: 2px;
        }

        span {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 16px;
            top: 16px;
            @include round-image(16px);
            border: 1px solid $color-gray;

            svg {
                opacity: 0;
            }
        }
    }
    &.simple{
        label{
            position: relative;
            padding-left: 24px;
            font-size: 14px;
            color: $color-black-light;
            font-weight: 500;
            input[type="radio"] {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
        
                &:checked {
                    & + span{
                        &::after{
                            content: "";
                            @include round-image(8px);
                            background-color: $color-white;
                            top: 5px;
                            left: 5px;
                            position: absolute;
                        }
                        &:before{
                            background-color: $color-primary;
                            border-color: $color-primary;
                        }
                    }
                }
            }
            span{
                &:before{
                    content: "";
                    @include round-image(16px);
                    border: 1px solid $color-border;
                    position: absolute;
                    left: 0;
                    top: 0;

                }
                
            }
        }
    }
}