@import '../../assets/scss/variables.scss';
.sizeOnboard{
    height: 100%;
&__left{
    max-width: 50%;
    padding: 15px;
    flex: 1;
    height: calc(100% - 15px);
    @include max-screen($desktop) {
        padding: 30px 15px;
    }
    @include max-screen($mobile) {
        max-width: 100%;
        height: auto;
        padding: 15px;
    }

}
&__back{
    padding: 0 0 15px;
}
&__header{
    padding-left: 18px;
    h2{
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 18px;
        font-weight: 600;
        color: $color-black-dark;
        
    }
    p{
        margin-top: 10px;
        font-size: 12px;
        font-weight: 500;
        padding-left: 27px;
    }
}
&__upload{
    flex: 1;
    padding: 10px;
    @include max-screen($mobile) {
        padding: 10px 0;
    }
}
&__uploadInner{
    width: 100%;
}

}