@import '../../assets/scss/variables.scss';

.sidebar {
    background-color: $color-white;
    border-radius: 10px;
    width: 56px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    position: fixed;
    left: 20px;
    top: 20px;
    z-index: 9999999;
    transition: all 0.2s;
    height: calc(100% - 40px);
    gap: 16px;
    @include max-screen($tablet) {
        width: 40px;
        padding: 10px;
        height: calc(100% - 10px);
        top: 5px;
        left: 5px;
    } 

    &__logo {
        margin-bottom: 50px;
    }
    &__menu{
        display: flex;
        align-items: center;
        line-height: 1;
    }
    &__menuIcon{
        min-width: 18px;
        min-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 18px;
        }
        svg{
            width: 18px;
            height: 18px;
        }
    }
    &__logoImg{
        top: -3px;
        position: relative;
    }
    &__menuText{
        font-size: 13px;
        font-weight: 500;
        color: $color-black-2;
        opacity: 0;
        padding-left: 8px;
        white-space: nowrap;
    }

    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;

        li {
            span {
                color: $color-black-2;

                &:hover,
                &.active {
                    color: $color-primary;
                    .sidebar__menuText, .sidebar__menuIcon{
                        color: $color-primary;
                    }
                }
            }
        }
    }
    &:hover{
        width: 167px;
        box-shadow: 2px 0px 5px rgb(0 0 0 / 10%);
        .sidebar__menuText{
            opacity: 1;
        }
    }
}