@import '../../assets/scss/variables.scss';
.fitModal{
    padding: 15px;
height: 100%;
overflow-y: auto;
&__add{
        font-size: 14px;
        font-weight: 600;
        gap: 8px;
        color: $color-primary;
}
&__info{
    flex: 1;
    margin: 30px 0;
    height: 100%;
    overflow-y: auto;
    &:hover::-webkit-scrollbar-thumb{
        background-color:  $blue3;
      }
      
}
&__infoTable{
    margin: 0 auto 70px;
    max-width: 300px;
}
&__grid{
    max-width: 257px;
    margin: 0 auto 70px;
}
&__gridHeader{
margin-bottom: 20px;
}
&__gridName{
    gap: 5px;
    font-size: 12px;
    font-weight: 500;
    color: $color-red;
    background-color: $color-error-3;
    border-radius: 55px;
    padding: 2px 8px;
    min-height: 22px;
}
&__gridEdit{
    font-size: 12px;
    font-weight: 500;
}
&__btn{
:global{
    .button{
        min-width: 201px;
    }
}
}
&__form{
    max-width: 314px;
}
&__formGroup{
    margin-top: 50px;
    h5{
        font-size: 14px;
        font-weight: 500;
        color: $color-black-light;
    }
    p{
        font-size: 14px;
        color: $color-black-light;
        margin-top: 12px;
        display: flex;
        gap: 8px;
        align-items: center;
    }
}
&__formSpace{
    margin-top: 118px;
}
// &__left{
//     max-width: 50%;
//     padding-right: 80px;
//     flex: 1;
//     @include max-screen($desktop) {
//         padding-right: 15px;
//     }
//     @include max-screen($mobile) {
//         max-width: 100%;
//         padding-right: 0px;
//     }
// }
// &__header{
//     padding-left: 18px;
//     h2{
//         display: flex;
//         align-items: center;
//         gap: 4px;
//         font-size: 18px;
//         font-weight: 600;
//         color: $color-black-dark;
        
//     }
// }
// &__upload{
//     flex: 1;
//     padding: 0 35px;
//     @include max-screen($desktop) {
//         padding: 15px;
//     }
//     @include max-screen($mobile) {
//         padding: 15px 0;
//     }
// }
// &__uploadInner{
//     width: 100%;
// }
// &__manually{
//     width: 100%;
//     border: 1px solid $color-gray-light;
//     border-radius: 12px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 16px;
//     font-weight: bold;
//     color: $color-primary;
//     font-size: 14px;
//     margin-top: 24px;
// }
// &__main{
//     flex: 1;
//     height: 100%;
// }
}