* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
 
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    font-family: $font-primary;
}

a {
    outline: none;
    cursor: pointer;
    text-decoration: none;
}

img {
    height: auto;
    display: block;
    max-width: 100%;
    font-size: 12px;
}
:focus {
    outline: none;
}
::-ms-expand {
    display: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
[role=button] {
    cursor: pointer;
}
[role=none] {
   pointer-events: none;
}
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 10px;
  }
   
::-webkit-scrollbar-track {
   background-color: transparent;
   border-radius: 10px;
  }
 
::-webkit-scrollbar-thumb {
   background-color: transparent;
   border-radius: 10px;
   transition: all 0.5s;
  }
  ::-webkit-scrollbar-thumb:hover{
    background-color:  $color-primary;
  }
  [role=cursor] {
    cursor: pointer;
}
[role=none] {
    cursor: default;
}
.h-100 {
    min-height: 100vh;
}
.h-full {
    min-height: 100%;
}
.w-full {
    width: 100%;
}
.fixedLoader{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgb(255 255 255 / 60%);
    z-index: 99;
}
.disabled{
    cursor: not-allowed;
    opacity: 0.5;
}
