@import '../../assets/scss/variables.scss';
.file {
    display: flex;
    padding: 8px 10px;
    border-radius: 5px;
    margin: 6px 0;
    font-size: 12px;
    font-weight: 500;
    align-items: center;
    justify-content: space-between;
    color: $color-black-dark;
    background-color: rgba($color-gray-light, 0.3);
    border: 1px solid $color-gray-light;
    span {
        text-align: left;
        color: $color-gray-dark;
        width: calc(100% - 25px);
    }
    svg {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
}