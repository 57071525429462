@import '../../assets/scss/variables.scss';

.install {
    max-width: 400px;
    margin: 0 auto;
    pointer-events: all;

    &__box{
        background-color: $color-white-5;
        border: 1px solid $color-gray-light;
        border-radius: 12px;
        padding: 24px;
        margin-top: 67px;
        p{
            margin-top: 23px;
            font-size: 16px;
            font-family: $font-primary;
            font-weight: 600;
                }
    }
    &__loginBox{
        text-align: center;
        min-height: 470px;
        h2{
            font-size: 20px;
            font-family: $font-primary;
            font-weight: 600;
        }
        p{
            margin: 10px 0 30px;
        }
    }
    &__button{
        max-width: 295px;
        margin: 23px auto 0;
        :global .button{
            font-weight: 600;
           
        }
    }
    &__info{
        margin: 85px 0;
        p{
            text-align: center;
            font-size: 14px;
            span{
                opacity: 0.5;
            }
        }
    }
    &__logo{
        gap: 10px;
        
        figure{
            width: 65px;
            height: 65px;
            border-radius: 10px;
            background-color: $color-primary;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        figcaption{
            font-size: 20px;
            font-family: $font-primary;
            font-weight: 600;
        }
    }
}