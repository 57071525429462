@import '../../assets/scss/variables.scss';
.details{
    padding: 15px 65px;
    margin-top: 15px;
    height: 100%;
    overflow-y: auto;
    &:hover::-webkit-scrollbar-thumb{
        background-color:  $color-primary;
      }
    @include max-screen($tablet) {
        padding: 0;
    }
    &__close{
        position: absolute;
        top: 15px;
        left: 15px;
    }
    &__wrapper{
        max-width: 630px;
        margin: 0 auto;
    }
    &__header{
        h2{
            font-size: 28px;
            font-weight: bold;
            display: inline-flex;
            font-family: $font-Archivo;
            align-items: center;
            gap: 30px;
            @include max-screen($tablet) {
                font-size: 18px;
            }
            :global{
                .MuiFormControlLabel-root{
                    margin: 0;
                    align-items: center;
                    gap: 15px;
                }
                .MuiTypography-root{
                    font-size: 16px;
                    font-weight: 500;
                    color: $color-black;
                }
            }
        }
    }
    &__info{
        margin-top: 30px;

        h3{
              font-weight: 600; 
              font-size: 14px;  
              display: flex;
              align-items: center;
              gap: 8px;
              
        }
        p{
            font-size: 14px;  
            margin-top: 10px;
            word-break: break-all;
            a{
                color: $color-black;
            }
        }
        ul{
            li{
                &:not(:last-child){
                    margin-bottom: 40px;
                }
            }
        }
    }
    &__color{
        @include round-image(20px);
        background-color: $color-black;
        border: 1px solid $color-border;
        box-shadow: 0px 0px 1px 1px $color-black;
    }
    &__Other{
        margin-top: 20px;

        h3{
              font-weight: 600; 
              font-size: 18px;  
              display: flex;
              align-items: center;
              gap: 8px;
              
        }
        p{
            font-size: 14px;  
            margin-top: 10px;
        
        }
        :global{
            .react-pdf__Page__canvas, .textLayer {
                margin: 0 auto;
                width: calc(50vw - 300px) !important;
                height: auto !important;
                @include max-screen($mobile) {
                    width: calc(100vw - 150px) !important;
                    height: auto !important;
                }
            }
        }
    }
    &__Images{
        margin-top: 15px;
        ul{
            gap: 18px;
            li{
                width: 100%;
                figure{
                    width: 100%;
                    
                }
            }
        }
    }
    &__ImagesDesc{
        margin-top: 25px;
        h4{
        color: $color-label;
        font-size: 14px;
        font-weight: 600;
        font-style: italic;
        }
        p{
            font-size: 14px;
            margin-top: 8px;
           
        }
    }
    &__ImagesDescSpace{
        margin-top: 46px;
    }
    &__ImagesTable{
        margin-top: 10px;
    }
    &__Shadow{
        position: relative;
        &::after{
            content: "";
            border-radius: 10px;
            box-shadow: 0px 0px 4px rgb(0 0 0 / 4%);
            width: calc(100% + 100px);
            height: calc(100% + 100px);
            position: absolute;
            left: -50px;
            top: -50px;
            pointer-events: none;
            @include max-screen($tablet) {
               display: none;
            }
        }
       
    }
}
