@import '../../assets/scss/variables.scss';
.selection{
height: 100%;
width: 100%;
padding: 73px 15px 15px;
@include max-screen($tablet) {
    padding: 15px;
}
&__scroll{
    height: 100%;
    overflow-y: auto;
    margin-top: 73px;
    @include max-screen($tablet) {
        margin-top: 15px;
    }
    &:hover::-webkit-scrollbar-thumb{
        background-color:  $blue3;
      }
}
&__images{
    flex: 1;
    max-width: 712px;
    margin: 0 auto;
    height: 100%;
    @include max-screen($tablet) {
        margin: 15px auto 0;
    }

    ul{
        gap: 3px;
        li{
            width: calc(33.3% - 2px);
            @include max-screen($mobile) {
                width: calc(50% - 2px);
            }
            figure{
                width: 100%;
                border-radius: 3px;
                overflow: hidden;
                padding-top: 100%;
                position: relative;
                border: 3px solid transparent;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                &.active{
                    border: 3px solid $color-primary;
    
                }
            }
        }
    }
}
&__select{
    max-width: 712px;
    margin: 0 auto;  
    gap: 14px;
    width: 100%;
}
&__col{
    width: calc(50% - 10px);
    @include max-screen($mobile) {
        width: 100%;
       }
       p{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        color: $color-black-light;
       }
}
&__button{
    padding-top: 15px;
}
&__imagesUploaded{
    margin-top: 20px;
    figure{
        position: relative;
    }
}
.close{
position: absolute;
z-index: 2;
right: 5px;
top: 5px;
}
.loader{
    max-width: 512px;
    min-height: 113px;
}
}

.batchUpload {
    padding: 45px 45px 21px 45px;
    position: relative;

    @include max-screen($tablet) {
        padding: 15px;
    }
&__close{
position: absolute;
right: 15px;
top: 15px;
}
    &__header {
        h3 {
            margin-top: 14px;
            font-size: 18px;
            font-weight: 600;
            color: $color-black-dark;
        }

        p {
            margin-top: 12px;
            color: $color-gray-dark;
            font-size: 14px;
        }
    }

    &__Uploaded {
        margin-top: 12px;

        p {

            color: $color-gray-dark;
            font-size: 14px;
        }
    }

    &__UploadedBar {
        margin: 50px 0;
    }

    &__Upload {
        margin-top: 41px;

    }

    &__Btn {
        margin-top: 41px;
        text-align: center;
    }

    &__file {
        display: flex;
        padding: 8px 10px;
        border-radius: 5px;
        margin: 6px 0;
        font-size: 12px;
        font-weight: 500;
        align-items: center;
        justify-content: space-between;
        color: $color-black-dark;
        background-color: rgba($color-gray-light, 0.3);
        border: 1px solid $color-gray-light;
        span {
            text-align: left;
            color: $color-gray-dark;
            width: calc(100% - 25px);
        }
        svg {
            width: 15px;
            height: 15px;
            cursor: pointer;
        }
    }
}