.auth,
.userVerify {
    max-width: 440px;
    margin: 0 auto;
    width: 100%;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100vh;
    pointer-events: all;
    &.register{
        display: block;
    }
    .logo {
        margin: 0 auto;
        width: 120px;
    }
    form {
        padding: 15px 0;
        width: 100%;
    }
    h2 {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 15px;
        @include max-screen($tablet) {
            font-size: 26px;
        }
    }
    h5 {
        font-size: 16px;
        color: $color-primary;
        font-weight: 400;
        margin-bottom: 5px;
    }

    p {
        font-size: 16px;
        color:  $color-gray-dark;
        margin-bottom: 30px;
        font-weight: 400;
    }

    &__footer {
        margin: 4px 0 24px 0;

        span {
            color: $color-secondary;
            font-size: 14px;
            font-weight: 600;
        }
    }

    &__bottom {
        text-align: center;
        margin-top: 32px;
        p {
            font-size: 14px;

            span {
                color: $color-secondary;
                font-weight: 600;
            }
        }

    }
    .top-img {
        margin: 0 auto;
    }
    .input {
        text-align: left;
        margin-bottom: 20px;
    }
}
.signUpMain, .userVerify{
    min-height: 0;
}
.userVerify{
.success-message {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
}
.signUpForm{
    text-align: left;
}
.messagePage {
    max-width: 320px;
    margin: 98px auto 32px;
    text-align: center;
    &__icon {
        border-radius: 28px;
        border: 10px solid $color-primary-light;
        background: $color-white-9;
        display: flex;
        width: 56px;
        height: 56px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        margin: 0 auto 14px;
    }
    h2 {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 15px;
        @include max-screen($tablet) {
            font-size: 26px;
        }
    }

    p {
        font-size: 16px;
        color: var(--color-gray);
        margin-bottom: 30px;
        font-weight: 400;
        strong {
            font-weight: 500;
        }

    }
    &__email {
        margin-top: 30px;
        font-size: 14px !important;
        span {
            font-weight: 600;
            color: $color-primary;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }

        }
    }
    &__back {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-size: 14px !important;
        font-weight: 600 !important;
        margin-bottom: 0 !important;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}
.w-full{
    width: 100%;
}
.copyBox {
    display: flex;
    align-items: center;
    border: 1px solid $color-border;
    border-radius: 10px;
    margin: 30px 0;
    justify-content: space-between;
    p{
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 5px;
    }
    .button{
        padding: 15px;
    }
}
.pointer{
    pointer-events: all;
}