@import '../../assets/scss/variables.scss';

.custom {
    width: 100%;

    &__top {
        width: 100%;

        img {
            min-width: 100%;
            max-height: 240px;
            object-fit: cover;
        }
    }

    &__header {
        padding: 24px 32px;
        gap: 15px;

        @include max-screen($tablet) {
            padding: 15px;
        }
    }
    &__back{
        font-size: 16px;
        gap: 5px;
        color: $color-gray-dark;
    }
    &__info {
        padding: 0 15px;
        gap: 32px;
        max-width: 630px;
        margin: 0 auto;

        @include max-screen($tablet) {
            padding: 15px;
            gap: 0;
        }

    }
    &__noData{
        min-height: calc(100vh - 200px)
    }
}
