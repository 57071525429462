@import '../../assets/scss/variables.scss';
.chip{
    background-color: $color-white-2;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 500;
    color: $color-black-light;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    gap: 6px;
    max-width: 200px;
    &.blue{
        background-color: $color-white-4;
        color: $color-black-light;
    }
    &.blueLight{
        background-color: $color-blue-3;
        color: $color-blue-2;
    }
    &.success{
        background-color: $color-green-3;
        color: $color-green;
    }
    &.error{
        background-color: $color-error-3;
        color: $color-error;
    }
}