.progress-bar {
    width: 100%;
    max-width: 295px;
    margin: 0 auto;
    text-align: center;
    &.large{
        max-width: 100%;
    }
    .MuiBox-root {
        padding: 0 !important;
    }
    p {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .MuiLinearProgress-root {
        height: 8px;
        border-radius: 4px;
        background-color: $blue6;
    }
    
    .MuiLinearProgress-bar {
        background-color: $color-primary;
        border-radius: 4px;
    }
}

.Toastify__toast {
    border: 1px solid #F2F4F7;
    padding: 16px 12px;
    width: 100%;
    max-width: 400px;
    .custom-toaster{
        &__info{
            display: flex;
            gap: 17px;
            align-items: center;
            figure{
                width: 40px;
            }
            figcaption{
                h5{
                    font-size: 14px;
                    font-weight: 500;
                    color: $color-black-dark;
                    font-family: $font-primary;
                }
                p{
                    font-size: 14px;
                    color: $color-gray-dark;
                    margin-top: 4px;
                    font-family: $font-primary;
                }
            }
        }
        &__action{
            margin-top: 16px;
            display: flex;
            align-items: center;
            gap: 12px;
            a{
                font-size: 14px;
                font-weight: 500;
                font-family: $font-primary;
            }
        }
        &__actionDismiss{
            color: $color-gray-dark;
        }
        &__actionView{
            color: $color-primary;
        }
    }

    .Toastify__close-button {
        opacity: 0.9;
        width: 20px;
        height: 20px;
        margin: 5px;
        svg{
            color: #667085;
            width: 18px;
            height: 16px;
        }
    }
}