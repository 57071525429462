@import '../../assets/scss/variables.scss';

.tryOn {
    width: 100%;
    height: 100%;
&__back{
    padding: 15px;
}
&__imagesBlur {
    filter: blur(3px);
}
&__button{
padding: 15px 48px 32px 15px;
}
    &__scroll{
        height: 100%;
      overflow-y: auto;
        flex: 1;
        @include max-screen($tablet) {
            margin-top: 15px;
        }
        &:hover::-webkit-scrollbar-thumb{
            background-color:  $blue3;
          }
    }
    &__main{
        height: 100%;
        width: 100%;
        padding:15px;
        @include max-screen($tablet) {
            padding: 15px 0;
         }
    }

    &__loopBox{
        padding: 5px;
    }
    &__images{
        flex: 1;
        max-width: 637px;
        margin: 0 auto ;
        height: 100%;
        width: 100%;
        @include max-screen($tablet) {
            margin: 15px auto 0;
        }
        &:hover::-webkit-scrollbar-thumb{
            background-color:  $blue3;
          }
          h2{
            font-size: 20px;
            color: $color-black;
            font-family: $font-Archivo;
            font-weight: 700;
            @include max-screen($tablet) {
                padding: 0;
             }
          }
     
    }
    &__radio{
        background-color: $color-green-7;
        border-radius: 8px;
        margin-top: 34px;
        padding: 12.5px 25px;
        gap: 10px;
        @include max-screen($tablet) {
            padding: 10px;
        }
    }
    &__btn{
        margin-top: 15px;
    }
    &__imagesBox{
        ul{
            gap: 17px;
            margin-top: 22px;
            li{
                width: calc(33.3% - 12px);
                @include max-screen($mobile) {
                    width: calc(50% - 9px);
                }
                figure{
                   position: relative;
                    width: 100%;
                    overflow: hidden;
                    padding-top: 135%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
        margin-bottom: 50px;
    p{
        font-size: 14px;
        font-weight: 600;
        color: $color-label;
        font-style: italic;
    }
    }
    &__loopBoxHeader{
        gap: 15px;
        @include max-screen($desktop) {
            margin-top: 0;
        }
        p{
            font-size: 14px;
            font-weight: 600;
            color: $color-label;
            font-style: italic;
        }
    }
    &__switch{
        font-size: 12px;
        color: $color-blackLight;
        gap: 10px;
      
    }
    &__toolTip{
        position: relative;
        cursor: pointer;
        img{
            width: 12px;
            opacity: 0.8;
        }
        &:hover{
            .tryOn__toolTipInfo{
                display: block;
            }
        }
    }
    &__toolTipInfo{
         display: none;
        position: absolute;
        right: 0;
        top: 20px;
        font-size: 13px;
        width: 295px;
        background-color: $color-white;
        padding: 14px 16px;
        box-shadow: 0px 4px 4px -4px rgba(0, 0, 0, 0.10);
        z-index: 9;
        @include max-screen($mobile) {
            width: 195px;
        }

    }
  
    &__textarea{
        max-width: 637px;
        margin: 30px auto ;
        width: 100%;
        padding: 0px;
        textarea{
            border: 1px solid $blue9;
            box-shadow: 0px 0px 0px 4px $blue3;
            width: 100%;
            padding: 12px;
            height: 55px;
            border-radius: 8px;
            resize: none;
            font-family: $font-primary;
            font-size: 13px;

        }
    }
}
:global  .toggledSize .toolTipInfo{
    right: 0;
    top: 19px;
    left: auto;
    bottom: auto;
}