.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary;
    padding: 15px 30px;
    height: 44px;
    font-size: 16px;
    color: $color-white;
    border: 0;
    font-weight: 500;
    font-family: $font-primary;
    cursor: pointer;
    gap: 7px;
    border-radius: 10px;
    transition: all 0.2s;

    &:hover {
        color: $color-white;
    }
    &.semibold{
        font-weight: 600;
    }
    &.sm{
        height: 40px;
        padding: 8px 15px;
    }
    &.xsm{
        height: 27px;
        font-size: 12px;
    }
    &.xs{
        height: 25px;
        font-size: 12px;
        padding: 5px 10px;
    }
    &.outline{
        border: 1px solid $color-primary;
        background-color: transparent;
        color: $color-primary;
    .MuiCircularProgress-circle{
        color: $color-primary !important;
    }
    }
&:disabled{
   opacity: 0.5;
    cursor: not-allowed;
}
 &.full{
    width: 100%;
 }
 &.rounded{
    border-radius: 55px;
 }
    &__loader {
		width: 40px;
		position: relative;
		span {
			width: 6px;
			height: 6px;
			margin-right: 3px;
			border-radius: 50%;
			display: inline-block;
			background: $color-white;
			animation: wave 1.3s linear infinite;
			&:nth-child(2) {
				animation-delay: -1.1s;
			}
			&:nth-child(3) {
				animation-delay: -0.9s;
			}
		}
	}  
    @keyframes wave {
		0%,
		60%,
		100% {
			transform: initial;
		}
		30% {
			transform: translateY(-4px);
		}
	} 
    &.loading{
        font-size: 0;
        &.contact-button{
            font-size: 16px;
        }
    }
    .MuiCircularProgress-circle {
        color: $color-white !important;
    }
}