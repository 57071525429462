@import '../../assets/scss/variables.scss';
.sizing{
    border:1px solid $color-white-2;
    border-radius: 4px;
    width: 100%;
    overflow: hidden;
    @include max-screen($mobile) {
        flex-direction: column-reverse;
        }
    figcaption{
        width: 50%;
        padding: 32px 20px 20px;
        @include max-screen($mobile) {
           width: 100%;
            }
        h3{
            font-size: 20px;
            font-weight: 500;
            display: inline-flex;
            align-items: center;
            gap: 11px;

        }
        p{
            margin-top: 16px;
            font-size: 14px;
        }
    }
    figure{
        width: 50%;
        background-color: $blue11;
        @include max-screen($mobile) {
            width: 100%;
             }
    }
    &__button{
        border-radius: 16px;
        margin-top: 62px;
        display: inline-flex;
        background-color: $blue12;
        padding: 6px 12px;
        font-size: 12px;
        font-weight: 500;
        gap: 16px;
        color: $color-black;
        span{
            color: $color-primary;
            display: inline-flex;
            align-items: center;
            gap: 5px;
        }
    }
}