@import '../../assets/scss/variables.scss';


.steps {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px 25px;

    @include max-screen($tablet) {
        padding: 15px;
    }
 
    &__info {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__box {
        h2 {
            margin-top: 25px;
            font-size: 26px;
            font-weight: normal;
            text-align: center;
            color: $color-black-light;
            margin-bottom: 26px;
            font-family: $font-Archivo;
            @include max-screen($tablet) {
                font-size: 20px;
            }
        }
    }

    &__wrapper {
        max-width: 360px;
        margin: 0 auto;
        width: 100%;
        &.large{
            max-width: 540px;
        }

        h1 {
            font-family: $font-SeriouslyNostalgic;
            font-weight: 500;
            font-size: 32px;
            padding-bottom: 55px;
            @include max-screen($tablet) {
                font-size: 26px;
                padding-bottom: 15px;
            }
            span {
                color: $color-primary;
                font-size: 40px;
                display: block;
                font-family: $font-SeriouslyNostalgic;
                @include max-screen($tablet) {
                    font-size: 32px;
                }
            }
        }
    }
&__appInstalled{
    text-align: center;
    margin-bottom: 27px;
    img{
        display: table;
        margin: 0 auto;
    }
    h2{
        margin: 0;
        color: $color-primary;
        font-family: $font-SeriouslyNostalgic;
        font-size: 36px;
    }
}
    &__step {
        gap: 5px;

        li {
            span {
                @include round-image(20px);
                font-size: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid transparent;

                &.active {
                    border: 1px solid $color-primary;
                    color: $color-primary;
                }
            }
        }
    }

    &__button {
        margin: 8px auto 0;
&.small{
    max-width: 201px;
}
    }
    &__accountText {
        margin-top: 20px;
        text-align: center;
        font-size: 14px;
        color: $color-gray-dark;

        span {
            font-weight: 500;
            color: $color-primary;
        }
    }

}

.step {
    &__step {
        gap: 5px;

        li {
            span {
                @include round-image(20px);
                font-size: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid transparent;

                &.active {
                    border: 1px solid $color-primary;
                    color: $color-primary;
                }
            }
        }
    }
    &__button {
        margin: 8px auto 0;

        &.small {
            max-width: 201px;
        }
    }
    &__radio {
        display: flex;
        flex-direction: column;
        padding: 26px 0 44px;
        gap: 12px;

    }

    &__shopify {
        border: 1px solid $color-border;
        border-radius: 8px;
        padding: 10px;

        figcaption {
            width: 100%;
            input{
                width: 100%;
                height: 30px;
                border: 0;
            }
            figure {
                width: 34px;
                height: 24px;
                border: 1px solid $color-white-2;
                margin-right: 17px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        p {
            font-size: 16px;
            color: $color-black-4;
        }

        span {
            color: $color-black-4;
            opacity: 0.5;
        }
    }

    &__install {
        margin-bottom: 7px;

        span {
            gap: 6px;
            color: $color-gray-dark;
            font-weight: 500;
            font-size: 12px;
        }
    }
}