@import '../../assets/scss/variables.scss';
.measurementTable{
    padding: 15px;
    @include max-screen($tablet) {
        padding: 15px;
    }
    &__add{
display: flex;
align-items: center;
gap: 11px;
font-size: 14px;
font-weight: 500;
color: $color-green-5;
padding: 7px 10px;
border-radius: 5px;
border: 1px solid $color-gray;
    }
    &__table{
        max-width: 823px;
        margin-top: 10px;
    }
}