@import 'assets/scss/variables.scss';
.dataFilter{
    gap: 15px;
    @include max-screen($small) {
        flex-direction: column;
        width: 100%;
    }
    :global{
        .MuiSelect-select {
            padding: 6px;
            font-size: 14px;
            outline: 0 0 0;
        }
        .MuiInputBase-input{
            padding-left: 16px;
        }
        .MuiOutlinedInput-notchedOutline{
        border: 1px solid $color-border;
        border-radius: 8px;
      
        }
        .Mui-focused {
            .MuiOutlinedInput-notchedOutline{
                border-color: $color-border !important;
            }
            
        }
        .button{
            position: relative;
            padding: 0 20px;
            border: 1px solid $color-border;
            background-color: $color-white;
            color: $color-blackLight;
            font-weight: 400;
            height: 35px;
            border-radius: 8px;
            font-size: 14px;
            @include max-screen($small) {
                width: 100%;
            }
            img{
                width: 14px;
            }
            &.active{
                &::after{
                    content: "";
                    @include round-image(8px);
                    background-color: rgb(255 0 81 / 90%);
                    position: absolute;
                    right: 4px;
                    top: 6px;
                }
            }
          
        }
    }
}