@import '../../assets/scss/variables.scss';

.product {
    padding: 24px 41px 24px 34px;
    height: 100%;

    @include max-screen($tablet) {
        padding: 15px;
    }

    &__main {
        flex: 1;
        height: 100%;
        overflow-y: auto;

        &:hover::-webkit-scrollbar-thumb {
            background-color: $blue3;
        }
    }

    &__button {
        padding-top: 15px;
    }

    &__grid {
        position: relative;
        flex: 1;
        height: 100%;
        :global .chipUi{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }
    }

    &__filtersRight {
        gap: 8px;
    }

    &__filters {
        gap: 8px;
    }

    &__filter {
        gap: 8px;
    }

    &__filterBtn {
        border-radius: 55px;
        border: 1px solid $color-primary;
        background-color: $color-primary;
        height: 30px;
        font-size: 12px;
        min-width: 120px;
        font-weight: 500;
        color: $color-white;
        gap: 7px;
        margin-bottom: 20px;
    }

    &__addDetails {
        width: 100%;
        max-width: 500px;
        position: absolute;
        top: -25px;
        pointer-events: none;
    }

    &__addDetailsHead {
        width: 100%;
        height: 25px;
        text-align: center;
        color: $color-white;
        font-size: 12px;
        font-weight: 500;
        background-color: $color-primary;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px 4px 0 0;

    }

    &__addDetailsBody {
        border: 1px solid $color-primary;
        height: 529px;
    }

    .productCell {
        .openButton {
            background-color: $color-white-3;
            color: $color-green-5;
            font-size: 12px;
            font-weight: 500;
            border: 0;
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 2px 4px;
            box-shadow: 1px 5px 5px rgb(0 0 0 / 15%);
            opacity: 0;

            @include max-screen($mobile) {
                opacity: 1;
            }
        }

        &:hover {
            .openButton {
                opacity: 1;
            }
        }
    }
    &__close {
        display: block;
        text-align: right;
        margin-bottom: 10px;
    }
    &__modal {
        min-height: 250px;
        @include max-screen($mobile) {
            min-height: 190px;
        }
        article {
            padding: 60px 58px 40px;
            @include max-screen($mobile) {
                padding: 20px 10px 10px;
            }
            h3 {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 12px;
                color: $color-black-dark;
            }
            p {
                font-size: 14px;
                color: $color-gray-dark;
            }
        }
    }
}

.productChip {
    line-height: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    text-transform: capitalize;
}