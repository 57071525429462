.MuiSwitch-root {
    width: 36px !important;
    height: 20px !important;
    padding: 0 !important;
    border-radius: 12px;

    .MuiButtonBase-root {
        top: 2px !important;
        left: 3px !important;
        padding: 0 !important;

        &.Mui-checked {
            color: $color-white;
            transform: translateX(14px);

            &+.MuiSwitch-track {
                background-color: $color-primary !important;
                opacity: 1 !important;
            }
        }
    }

    .MuiSwitch-thumb {
        width: 16px !important;
        height: 16px !important;
    }

    .MuiSwitch-track {
        background: $color-white-2 !important;
        opacity: 1 !important;
    }
}