@import '../../assets/scss/variables.scss';
.selection{
height: 100%;
width: 100%;
padding:15px;
@include max-screen($tablet) {
    padding: 15px 0;
 }
&__scroll{
    height: 100%;
    overflow-y: auto;
    @include max-screen($tablet) {
        margin-top: 15px;
    }
    &:hover::-webkit-scrollbar-thumb{
        background-color:  $blue3;
      }
}
&__close{
    position: absolute;
    top: 15px;
    left: 15px;
}
&__loopBox{
    padding: 5px;
}
&__tone{
    background-color: #F8F9FC;
    padding: 2px 8px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #363F72;
}
&__images{
    flex: 1;
    max-width: 637px;
    margin: 0 auto ;
    height: 100%;
    @include max-screen($tablet) {
        margin: 15px auto 0;
    }
    &:hover::-webkit-scrollbar-thumb{
        background-color:  $blue3;
      }
      h2{
        font-size: 28px;
        color: $color-black;
        font-family: $font-Archivo;
        font-weight: 700;
        padding-left: 28px;
        display: inline-flex;
        align-items: center;
        margin-bottom: 20px;
        gap: 10px;
        @include max-screen($tablet) {
            padding: 0;
         }
      }
    ul{
        gap: 17px;
        margin-top: 22px;
        li{
            width: calc(33.3% - 12px);
            @include max-screen($mobile) {
                width: calc(50% - 9px);
            }
            figure{
               position: relative;
                width: 100%;
                overflow: hidden;
                padding-top: 135%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}
&__button{
    padding: 15px;
}
&__radio{
    background-color: $color-green-7;
    border-radius: 8px;
    margin-top: 34px;
    padding: 12.5px 25px;
    gap: 10px;
    @include max-screen($tablet) {
        padding: 10px;
    }
}
&__imagesBox{
    box-shadow: 0px 1px 4px rgb(0 0 0 / 5%);
    border-radius: 10px;
padding: 20px 40px;
margin-top: 23px;
@include max-screen($tablet) {
   padding: 0;
}
p{
    font-size: 14px;
    font-weight: 600;
    color: $color-label;
    font-style: italic;
}
}
&__finalTuningPill{
    background-color:$color-lightBlue ;
    border-radius: 8px;
    padding: 7px 12px;
    gap: 10px;
    display: inline-flex;
    margin-top: 10px;
}
&__finalTuningLine{
    height: 50px;
    border-left: 3px dashed $color-primary;
}
&__textarea{
    max-width: 637px;
    margin: 30px auto 0 ;
    width: 100%;
    padding: 0 5px;
    textarea{
        border: 1px solid $blue9;
        box-shadow: 0px 0px 0px 4px $blue3;
        width: 100%;
        padding: 12px;
        height: 128px;
        border-radius: 8px;
        resize: none;
        font-family: $font-primary;
        font-size: 14px;

    }
}
}