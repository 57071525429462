.custom-table {
    border-top: 1px solid $color-gray;
    overflow: hidden;
    height: 100%;
    width: 100%;
    &.borderTopHide{
        height: auto;
    }

    &.product-table {
        border-top: 0;

        .custom-table__inner {
            padding-top: 25px;
            background-color: $color-white;

            table {
                tr {

                    th,
                    td {

                        border-right: 0;
                        font-family: $font-primary;

                        &:not(:first-child) {
                            border-left: 1px solid $color-gray;
                        }
                    }

                    th {
                        &::before {
                            content: "";
                            width: 100%;
                            height: 26px;
                            background-color: $color-white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 4px 4px 0 0;
                            position: absolute;
                            left: 0;
                            top: -26px;
                            z-index: 0;
                            border-radius: 0;
                        }
                    }
                    
                }
            }
        }
    }

    &__inner {
        overflow-x: auto;
        height: 100%;

        &:hover::-webkit-scrollbar-thumb {
            background-color: $blue3;
        }

        table {
            width: 100%;
            border-collapse: collapse;
          
            tr {
                th {
                    padding: 8px 12px;
                    font-size: 14px;
                    font-weight: 500;
                    color: $color-green-5;
                    text-align: left;
                    border-bottom: 1px solid $color-gray;
                    white-space: nowrap;
                    font-family: $font-primary;
                    height: 38px;
                    border-right: 1px solid $color-gray;

                    &:first-child {

                        width: 200px;

                    }

                    &:last-child {
                        border-right: 0;
                    }

                    &.MuiTableCell-alignCenter {
                        text-align: center;
                    }
                }

                td {
                    font-size: 13px;
                    font-weight: 500;
                    color: $color-black-2;
                    padding: 8px 12px;
                    border-bottom: 1px solid $color-gray;
                    border-right: 1px solid $color-gray;
                    height: 38px;
                    font-family: $font-primary;

                    &:last-child {
                        border-right: 0;
                    }

                }

                &:last-child {
                    td {
                        border-bottom: 0;
                    }
                }

               
                &.Mui-selected{
                    td{
                        background-color: #f2f2f2 !important;
                    }
                    
                   }
            }
            thead{
                tr{
                    &:first-child {
                        th {
                            position: sticky;
                            top: 0;
                            background-color: $color-white;
                            z-index: 1;
    
                            &:first-child {
                                z-index: 4 !important;
                            }
    
                            &:nth-child(2) {
                                z-index: 3 !important;
                            }
                        }
                    }
                }
            }
        }
    }

    &.size-table {
        border: 0;
        box-shadow: 0 0 0;
        border-radius: 0;

        table {
            tr {
                th {
                    background-color: $color-white-8;
                    border: 0;
                    font-size: 10px;
                    padding: 2px 4px;
                    border: 4px solid $color-white;
                    border-radius: 4px;
                    text-align: center;

                    &.transparent {
                        background-color: transparent;
                    }
                }

                td {
                    padding: 10px 4px;
                    border: 0;
                }
            }
        }
    }

    .size-area {
        background-color: $color-white-8;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        border-radius: 4px;
        min-width: 56px;
        font-size: 12px;
        color: $color-black-dark;
        position: relative;
        padding: 5px 5px 10px;

        span {
            position: absolute;
            right: 4px;
            bottom: 2px;
            font-size: 8px;
        }

        &.selected {
            background-color: $color-white-7;
        }
    }

    .link {
        color: $color-primary;
        display: inline-flex;
    }

    .order-id {
        font-weight: 500;
        color: $color-black-dark;
    }

    .date {
        white-space: nowrap;
    }

    .table-input {
        input {
            width: 60px;
            border: 0;
            padding: 4px 0;
        }
    }

    &.firstColSticky {
        table {
            tr {

                th:nth-child(1),
                td:nth-child(1) {
                    @include min-screen($mobile) {
                        position: sticky;
                        left: 0;
                        background-color: $color-white;
                        z-index: 2;
                    }
                   
                }
                &:hover{
                    td:nth-child(1){
                        background-color: #f2f2f2!important;
                    }
                   
                }
            }
        }
    }

    &.secondColSticky {
        table {
            tr {

                th:nth-child(2),
                td:nth-child(2) {
                    @include min-screen($mobile) {
                        position: sticky;
                        left: 0;
                        background-color: $color-white;
                        z-index: 2;
                    }
                }
                &:hover{
                    td:nth-child(2){
                        background-color: #f2f2f2 !important;
                    }
                   
                }
            }
        }
    }
}
.MuiTableRow-root{
    &:hover{
            background-color: #f2f2f2 !important;
       
    }
}
.table-link {
    color: $color-green-5;
}

.productCell {
    .openButton {
        background-color: $color-white-3;
        color: $color-green-5;
        font-size: 12px;
        font-weight: 500;
        border: 0;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 2px 4px;
        box-shadow: 1px 5px 5px rgb(0 0 0 / 15%);
        opacity: 0;
        width: 60px;
        line-height: 1;

        @include max-screen($mobile) {
            opacity: 1;
        }
    }

    &:hover {
        .openButton {
            opacity: 1;
        }
    }
}

.productChip {
    line-height: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    text-transform: capitalize;
}

.head-with-icon {
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    color: $color-green-5;
    font-family: $font-primary;
    gap: 8px;

    img {
        max-width: 15px;
        max-height: 15px;
    }
}

// .fitModel-top-border {
//     border-top: 1px solid $color-primary !important;
//     position: relative;

//     &::before {
//         content: "Add details" !important;
//         width: calc(300% + 4px) !important;
//         height: 25px !important;
//         text-align: center;
//         color: $color-white;
//         font-size: 12px;
//         font-weight: 500;
//         background-color: $color-primary !important;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         border-radius: 4px 4px 0 0;
//         position: absolute;
//         left: -1px !important;
//         top: -26px;
//         z-index: 1;
//     }

//     &.baseSize-border {
//         &::before {
//             display: none !important;
//         }
//     }

// }

// .baseSize-border {
//     border-right: 1px solid $color-primary !important;

//     &.baseSize-border-noBorder {
//         border-right: 0 !important;
//     }
// }

// .fitModel-border {
//     border-left: 1px solid $color-primary !important;
// }

// .fitModal-boarder-bottom {
//     border-bottom: 1px solid $color-primary !important;
// }

.available {
    display: flex;
    align-items: center;
    gap: 9px;
}

.not-available {
    font-style: italic;

}

.checkBoxHeader {
    width: 38px !important;
}

.desc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
}

.checkboxTd {
    width: 38px !important;
    min-width:38px !important;
}
.italic-text{
    font-style: italic;
}
.tableChip{
    border-radius: 16px;
background: $color-blue-3;
mix-blend-mode: multiply;
display: flex;
padding: 2px 8px;
align-items: center;
color: $color-primary;
font-size: 12px;
font-weight: 500;
&.chipRed{
    color: #C11574;
    background-color: #FDF2FA;
}
}
.chipGreen{
    color: $success;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    border-radius: 16px;
    background: $successBg;
    mix-blend-mode: multiply;
    display: inline-flex;
    padding: 2px 8px 2px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;

}
.note{
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 132px;
}
.action{
    display: flex;
    align-items: center;
    gap: 15px;
    img{
        width: 20px;
        height: 20px;
        min-width: 20px;
    }
}
.helpIcon{
    width: 12px;
}
.inputTd{
    input {
        border: none;
        outline: none;
        min-width: 40px;
        width: 0;
        font-size: 13px;
        font-weight: 400;
        font-family: $font-primary;
        // &::selection {
        //         background-color: transparent;
        //     }
        cursor: pointer;

        .tableCustom & {
            padding: 5px 2px;
            margin-right: 2px;
            border-radius: 4px;
            min-width: 85px;
        }

       
    } 
}
.custom-name{
    margin: 0;
    font-weight: 400;
    color: $color-black-dark;
    font-size: 13px;
}
.MuiTableRow-root{
    cursor: default !important;
}
.MuiTableRow-hover:hover{
    background-color: #f2f2f2 !important;
}
.blue-dot{
@include round-image(8px);
background-color: $color-primary;
&.transparent{
    background-color: transparent;
}
}
.eligible-text{
    font-size: 12px;
    font-weight: 500;
}