
// colors
$color-primary: #5932F3;
$color-secondary: #6941C6;
$color-primary-light: #F9F5FF;
$color-white: #fff;
$color-white-2:#F2F4F7;
$color-white-3:#F9FAFB;
$color-white-4:#F3EFFF;
$color-white-5:#F5F5F4;
$color-white-6:#e6e4f7;
$color-white-7:#D6D6FC;
$color-white-8:#F8F9FB;
$color-white-9:#F4EBFF;
$success: #027A48;
$successBg: #ECFDF3;
$color-black: #000;
$color-redBg: #FFF1F3;
$color-black-dark:#101828;
$color-black-2: #505050;
$color-black-3:#3D3C3F;
$color-black-4:#667085;
$color-black-5:#878787;
$color-black-light: #344054;
$color-blue-2:#175CD3;
$color-blue-3:#EFF8FF;
$color-blue-4:#c4baef;
$color-gray:#D9D9D9;
$color-gray-2:#E5E5E5;
$color-gray-light:#EAECF0;
$color-gray-dark:#475467;
$color-error:#da1818;
$color-error-2:#ffe4e4;
$color-error-3:#FEF3F2;
$color-cream:#E5E4E0;
$color-green-light:#ECFDF3;
$color-green:#027A48;
$color-green-2:#12B76A;
$color-green-3:#ECFDF3;
$color-gray-5:#C9C9C5;
$color-gray-6:#9795A2;
$color-green-7:#FBFBFB;
$color-blackLight: #101828;
$color-lightBlue: #F9FAFB;
$color-label: #344054;
$color-input: #667085;
$color-inputDark: #9795A2;
$color-border: #D0D5DD;
$table-header: #FCFCFD;
$color-skeleton: #D9D9D9;
$blue1: #6941C6;
$blue2: #7F56D9;
$blue3: #F4EBFF;
$blue4: #E9D7FE;
$blue5: #9E77ED;
$blue6: #EAECF0;
$blue7: #F9F5FF;
$blue8: #8D7FC6;
$blue9: #D6BBFB;
$blue10:#7251F5;
$blue11:#DEE9FF;
$blue12:#F4F1FF;
$blue13:#E6E7FC;
$blue14:#F5EBFD;
$color-pink:#FFD2CF;
$color-green-4:#E6FCE9;
$color-green-5:#808795;
$color-green-6:#C4C4C4;



$color-red: #C01048;
$color-warning:#FFA833;

// typography
$font-size-base: 16px;
$line-height-base: 1.5;
$font-primary:"Inter", sans-serif;
$font-Archivo:"Archivo", sans-serif;
$font-SeriouslyNostalgic:'SeriouslyNostalgic Fn', sans-serif;
$font-Switzer:'Switzer', sans-serif;


// utilities 
$radius: 10px;
$shadow:0px 0px 10px 0px rgb(0 0 0 / 10%);
$shadow2:0px 0px 2px 0px rgba(0, 0, 0, 0.1);

// breakpoints
$small: 575px;
$mobile: 767px;
$tablet: 991px;
$desktop: 1199px;
$desktop-large: 1340px;
$desktop-x-large: 1440px;
$desktop-xlarge: 1599px;
// image path
$imgPath: "../../assets/images/";

// mixin
@import 'mixins';



:root {
    --color-blackLight: #{$color-blackLight};
    --color-primary: #{$color-primary};
    --color-bgLightBlue: #{$color-lightBlue};
    --color-bgWhite: #{$color-white};
    --color-gray:#{$color-gray};
    --color-label:#{$color-label};
    --color-border:#{$color-border};
    --color-input:#{$color-input};
    --color-black: #{$color-black};
    --color-card: #{$color-white};
    --color-header: #{$table-header};
    --color-BoxHeader: #{$color-lightBlue};
    --color-tabBg: #{$blue7};
    --color-border: #{$blue6};
}