.custom-select{
    display: inline-flex;
    flex-direction: column;
    .custom-select-wrapper{
        display: inline-flex;
        align-items: center;
        gap: 8px;
    }
    .custom-label{
        font-size: 14px;
        color: #344054;
        align-items: center;
    }
&.full{
    width: 100%;
}
    &.bgGray{
        .MuiInputBase-root{
            background-color: $color-white-2;
        } 
    }
    &.bgWhite{
        .MuiInputBase-root{
            background-color: $color-white;
        }  
    }
    &.bgGreen{
        .MuiInputBase-root{
            background-color: $color-green-light;
            
            .MuiSelect-select{
                color:  $color-green;
            }
            .custom-select__arrow{
                color:  $color-green;
            }
        }   
    }
    &.outline{
        .MuiInputBase-root{
            background-color: transparent;
            border: 1px solid $color-green-5;
            height: 22px;
            
            .MuiSelect-select{
                color:  $color-green-5;
                display: flex;
                align-items: center;
                gap: 5px;
                padding-right: 24px;
            }
            .custom-select__arrow{
                color:  $color-green-5;
                height: 22px;
                top: -2px;
                right: 7px;
            }
        }  
    }
    &.outlineShadow{
        .MuiInputBase-root{
        background-color: $color-white;
        border: 1px solid $color-border;
        border-radius: 8px;
        height: 44px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
    .custom-select__arrow{
        top: 9px;
    }
    .MuiSelect-select{
        font-size: 16px;
        color: $color-black-4;
    }
    }
    &.iconSpace{
        .MuiSelect-select{
            padding-left: 24px;
        }
    }
    .MuiInputBase-root{
        background-color: $color-white-6;
        border-radius: 16px;
    }
    .MuiOutlinedInput-notchedOutline{
        border: 0;
        
    }
    .MuiSelect-select{
        padding: 2px 28px 2px 6px;
        font-size: 12px;
        font-weight: 500;
        color: $color-black-light;
        font-family: $font-primary;
    }
    &__arrow{
        position: absolute;
        right: 10px;
        top: 1px;
        pointer-events: none;
    }
}
.customSelect {
    text-align: left;
    margin-bottom: 20px;

    label {
        font-size: 14px;
        font-weight: 500;
        color: $color-black-light;
        display: block;
        margin-bottom: 5px;
    }

    .MuiInputBase-root {
        border: 1px solid $color-border !important;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
        outline: none !important;
        border-radius: 8px;

        .MuiSelect-select {
            width: 100%;
            padding: 10px 14px;
            font-size: 16px;
            box-shadow: 0 0 0 !important;
            border: 0 !important;
            outline: none !important;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        display: none !important;
    }

}
.select-error {
    color: $color-error;
    font-size: 14px;
    margin-top: 2px;
}

.custom-auto-complete{
    line-height: 1;
    width: 100%;
    .label{
       font-size: 14px;
       font-weight: 500; 
       color: $color-label;
       margin-bottom: 6px;
       display: block;
    }
    .MuiAutocomplete-root{
        background-color: $color-white-2;
        border-radius: 55px;
    }
    .MuiInputBase-root{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-right: 28px !important;
        .MuiAutocomplete-input{
            font-size: 14px;
            padding: 0 !important;
        }
    }
    .MuiOutlinedInput-notchedOutline{
        border: 0;
    }
    .MuiAutocomplete-clearIndicator{
        display: none !important;
    }
    .custom-select__arrow {
        position: absolute;
        right: 10px;
        top: 7px;
        pointer-events: none;
        display: inline-flex;
    }
    &.outlined{
        .MuiAutocomplete-root{
            background-color: $color-white;
            border: 1px solid $color-border;
            border-radius: 8px;
            height: 44px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
           
        }
        .MuiInputBase-root{
            padding-top: 11px !important;
        }
        .custom-select__arrow{
            top: 17px;
        }
    }
}
.select-custom-width{
    width: 100%;
}
.MuiPaper-root.MuiAutocomplete-paper {
    width: 100%;
    min-width: 200px;
    padding: 0 !important;
    margin: 10px 0 0 -10px !important;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
   
    ul{
        &:hover::-webkit-scrollbar-thumb{
            background-color:  $blue3;
          }
        li{
            font-size: 14px;
            color: $color-black;
            font-family: $font-primary;
            word-break: break-all;
        }
    }
}
.select-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select-icon {min-width: 14px;}