@import '../../assets/scss/variables.scss';
.dashboard{
    background-color: $color-cream;
    padding: 20px 25px;
    min-height: 100vh;
    gap: 13px;
  
    @include max-screen($tablet) {
       padding: 5px;
       gap: 5px;
     }
    &__right{
        background-color: $color-white;
        width: 100%;
        border-radius: 10px;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        position: relative;
        margin-left: 76px;
        @include max-screen($tablet) {
            margin-left: 46px;
          }
        
    }
    &__sync{
        background-color: $color-white-3;
        padding: 7px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 14px;
        color: $color-gray-dark;
        border-radius: 8px;
    }
    &__header{
        padding: 10px 24px;
        border-bottom: 1px solid $color-gray;
        min-height: 56px;
        @include max-screen($tablet) {
            padding: 10px;
          }
        h1{
            font-size: 20px;
            font-weight: 400;
            line-height: 1;
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
    &__content{
        flex: 1;
        overflow-y: auto;
        max-height: calc(100vh - 96px);
        &:hover::-webkit-scrollbar-thumb{
            background-color:  $blue3;
          }
        @include max-screen($tablet) {
            max-height: calc(100vh - 66px);
          }
    }
    .appNotInstalled {
        font-size: 12px;
        color: $color-warning;
        font-weight: 500;
        padding: 2px 8px;
        border-radius: 16px;
        display: table;
        white-space: nowrap;
        background-color: rgb($color-warning, 0.1);
        &.green{
            background-color: $successBg;
            color: $success;
        }
        &.blue{
            color: $color-blue-2;
            background-color: rgba($color-blue-2, 0.1);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            svg{
                width: 20px;
            }
        }
    }
}