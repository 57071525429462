@import '../../assets/scss/variables.scss';
.tryOnBoarding{
    height: 100%;
    padding: 15px;
    &__back{
        padding-bottom: 15px;
    }
  &__info{
overflow-y: auto;
    height: 100%;
    flex: 1;
  }
  &__button{
    padding-top: 30px;
  }
  &__grid{
    padding-top: 24px;
    max-width: 1083px;
    height: 100%;
    overflow: auto;
    flex: 1;
    table{
        width: 1083px;
        tr{
            th{
                width: 280px ;
            }
        }
    }
  }
  &__noData{
    :global{
        .noData{
            transform: translateY(-50px);
            padding: 0;
        }
    }
  }
  &__header{
    gap: 8px;
    :global{
        .custom-select.outline{
            min-width: 120px;
            .MuiInputBase-root{
                height: 30px;
                .custom-select__arrow{
                    top: 2px;
                }
            }
        } 
        .button{
            height: 30px;
            font-size: 12px;
            min-width: 120px;
        }
    }
  }
  &__modal {
    min-height: 228px;
    @include max-screen($mobile) {
        min-height: 190px;
    }
    article {
        padding: 0 58px 40px;
        @include max-screen($mobile) {
            padding: 20px 10px 10px;
        }
        h3 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 12px;
            color: $color-black-dark;
        }
        p {
            font-size: 14px;
            color: $color-gray-dark;
        }
    }
}
&__close {
    display: block;
    text-align: right;
    margin-bottom: 10px;
}
}