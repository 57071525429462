@import '../../assets/scss/variables.scss';
.fitModal{
padding: 37px 26px 26px;
height: 100%;
@include max-screen($mobile) {
    padding: 15px;
}
&__add{
        font-size: 14px;
        font-weight: 600;
        gap: 8px;
        color: $color-primary;
}
&__info{
    flex: 1;
    margin: 30px 0;
    height: 100%;
    overflow-y: auto;
}
&__editBtn{
    gap: 5px;
}
&__gridDelete{
    color: $color-error;
    font-size: 12px;
    font-weight: 500;
    display: inline-flex;
    svg{
        width: 16px;
        height: 16px;
    }
}
&__grid{
    max-width: 300px;
    position: relative;
    table{
        tr{
            th, td{
                &:last-child{
                    border-right: 1px solid $color-gray;
                }
               
            }
            &:last-child{
                td{
                    border-bottom: 1px solid $color-gray;
                }
            }
        }
      }
}
&__gridHeader{
margin-bottom: 20px;
}
&__gridName{
    gap: 5px;
    font-size: 12px;
    font-weight: 500;
    color: $color-red;
    background-color: $color-error-3;
    border-radius: 55px;
    padding: 2px 8px;
    min-height: 22px;
    span{
        text-overflow: ellipsis;  
        overflow:hidden;           
        white-space:nowrap;         
        max-width: 100px;  
    }
    input{
        background-color: transparent;
        border: 0;
        width: fit-content;
        font-size: 12px;
        font-weight: 500;
        width: 100px;
        text-overflow: ellipsis;  
        overflow:hidden;           
        white-space:nowrap; 
        color: $color-red;
        font-family: $font-primary;
    }
}
&__gridEdit{
    font-size: 12px;
    font-weight: 500;
}
&__btn{
:global{
    .button{
        min-width: 201px;
    }
}
}
&__form{
    max-width: 314px;
}
&__formGroup{
    margin-top: 50px;
    h5{
        font-size: 14px;
        font-weight: 500;
        color: $color-black-light;
    }
    p{
        font-size: 14px;
        color: $color-black-light;
        margin-top: 12px;
        display: flex;
        gap: 8px;
        align-items: center;
    }
}
&__formSpace{
    margin-top: 118px;
}
&__confirm{
    text-align: center;
    padding: 32px 16px;
    h2{
        font-size: 20px;
        font-weight:600;
        color: $color-black-light;
    }
    p{
        font-size: 14px;
        color: $color-black-light;
        margin: 12px 0 0 0;
}
}
&__confirmBtn{
    gap: 10px;
    margin-top: 32px;
}
}