@import '../../assets/scss/variables.scss';

.custom{
    width: 100%;
height: 100%;
position: relative;
&__topGradient{
    height: 103px;
    width: 100%;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
&__cardMain{
    width: 100%;
    padding: 30px 48px;
    height: 100%;

    @include max-screen($tablet) {
        padding: 15px;
    }
}
}