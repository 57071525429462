.MuiCheckbox-root {
    padding: 0 9px !important;

    .MuiSvgIcon-root {
        color: var(--color-border) !important;
        font-size: 20px !important;
        border: 1px solid $color-border;
        border-radius: 6px;

        path {
            display: none;
        }
    }

    .MuiFormControlLabel-label {
        color: var(--color-label) !important;
        font-weight: 500 !important;
    }

    &.Mui-checked {
        .MuiSvgIcon-root {
            color: var(--color-primary) !important;
            background-image: url('../../assets/images/check-tick.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-color: $blue7;
        }
    }
}

.MuiSkeleton-root {
    background-color: $color-skeleton !important;
}

.MuiDrawer-paper {
    .sidebar {
        @include max-screen($tablet) {
            display: flex;
        }
    }

}

.MuiTabs-root {
    border-bottom: 1px solid var(--color-border);
    min-height: 0 !important;

    .MuiButtonBase-root {
        font-size: 14px;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 700;
        color: $color-input;
        text-transform: capitalize;
        letter-spacing: 0;
        padding: 10px;
        min-height: 0;

        &.Mui-selected {
            color: $color-primary;
        }
    }

    .MuiTabs-indicator {
        background-color: $color-primary;
    }
}

.MuiBox-root {
    padding: 24px 0 !important;
}



.MuiTableContainer-root {
    .tableCustom & {
        overflow-x: hidden;
    }

    .MuiTableRow-root {
        .table-group & {
            cursor: default;
        }
    }

    // &.tableWithOutCheck{
    //     .MuiTableCell-root {

    //         &.MuiTableCell-head {
    //             border-right: 5px solid var(--color-bgWhite);

    //         }

    //     } 
    // }

    &.tableWithCheck {
        margin: 0;
        border-radius: 0;
        box-shadow: 0 0 0;

        .MuiTableCell-root {
            &.MuiTableCell-head {
                &:not(:first-child) {
                    border-left: 0;
                }
            }
        }
    }

    table {
        width: 100%;

        @include max-screen($desktop) {
            width: 1024px;
        }

        .tableCustom & {
            @include max-screen($desktop) {
                width: 100%;
            }
        }

    }

    .MuiTableCell-root {
        background-color: var(--color-bgWhite);
        padding: 16px 10px;
        color: var(--color-gray);
        font-size: 14px;
        font-weight: 400;
        font-family: $font-primary;
        border-bottom: 1px solid var(--color-border);
        .calibrationTable &{
            background-color: var(--color-bgWhite);
            border-right: 22px solid $color-white;
        }
        .table-group & {
            padding: 16px 24px;
        }

        .tableCustom & {
            height: 68px;
        }

        &.MuiTableCell-head {
            background-color: var(--color-BoxHeader);
            padding: 12px 10px;
            color: var(--color-gray);
            font-size: 12px;
            font-weight: 500;
            font-family: $font-primary;

            .table-group & {
                background-color: var(--color-bgWhite);
                padding: 12px 24px;
            }
            .calibrationTable &{
                background-color: var(--color-bgWhite);
                border-right: 22px solid $color-white;
            }

            &:nth-child(2),
            &:nth-child(3) {
                min-width: 126px;

                .reviewTable & {
                    min-width: 80px;
                }
            }


            .MuiCheckbox-root {
                .MuiSvgIcon-root {
                    color: var(--color-primary) !important;
                    background-image: url('../../assets/images/uncheck.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-color: $blue7;
                }

                &.Mui-checked {
                    .MuiSvgIcon-root {
                        color: var(--color-primary) !important;
                        background-image: url('../../assets/images/check-tick.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-color: $blue7;
                    }
                }
            }
        }

        input {
            border: none;
            outline: none;
            min-width: 40px;
            width: 0;
            font-size: 14px;
            font-weight: 500;
            font-family: $font-primary;
            // &::selection {
            //         background-color: transparent;
            //     }
            cursor: pointer;

            .tableCustom & {
                padding: 5px 2px;
                margin-right: 2px;
                border-radius: 4px;
                min-width: 85px;
            }

            &.show-border {
                border: 1px solid $color-border;
            }
        }

        p {
            min-height: 20px;
        }

        strong {
            font-weight: 500;
        }

        h5 {
            margin: 0;
            font-weight: 500;
            color: var(--color-blackLight);
            font-size: 14px;
        }
    }

}

.expendTable {
    button {
        color: $color-primary;
        font-size: 14px;
        font-weight: 600;
        padding: 15px;
        cursor: pointer;
        background-color: transparent;
        border: 0;
    }
}

.chip {
    border-radius: 16px;
    border: 2px solid $success;
    padding: 0 10px;
    display: inline-flex;
    color: $success;
    font-size: 12px;
    font-weight: 500;
}
.date{
    white-space: nowrap;
}
.tableAction {
    gap: 10px;

    img {
        min-width: 20px;
        cursor: pointer;
    }

    a {
        color: $color-primary;
        font-weight: 600;
        cursor: pointer;
    }
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
    margin: 0 !important;
}

.MuiTablePagination-selectLabel {
    @include max-screen($tablet) {
        display: none;
    }
}

.btnTableMain {
    padding: 15px;
    font-size: 14px;
    color: var(--color-gray);

    .btnTable {
        gap: 10px;
    }

}

.success-message {
    width: 100%;

    h1 {
        margin: 20px 0;
    }

    .success-title {
        font-size: 26px;
    }
}

.progress {
    display: inline-flex;
    padding: 2px 10px;
    align-items: center;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 400;
    background-color: $blue7;
    color: $color-primary;
    margin-left: 10px;

    &.small {
        font-size: 12px;
        font-weight: 500;
        color: $blue1;
    }
}

.MuiSwitch-root {
    width: 36px !important;
    height: 20px !important;
    padding: 0 !important;
    border-radius: 12px;

    .MuiButtonBase-root {
        top: 2px !important;
        left: 3px !important;
        padding: 0 !important;

        &.Mui-checked {
            color: $color-white;
            transform: translateX(14px);

            &+.MuiSwitch-track {
                background-color: $color-primary !important;
                opacity: 1 !important;
            }
        }
    }

    .MuiSwitch-thumb {
        width: 16px !important;
        height: 16px !important;
    }

    .MuiSwitch-track {
        background: $color-white-2 !important;
        opacity: 1 !important;
    }
}

.productTabs {
    display: flex;
    margin: 38px auto;

    .MuiTabs-root {
        border-radius: 90px;
        background: rgba($color-skeleton, 0.50);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 !important;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        border: 1px solid var(--gray-300, #D0D5DD);

        .MuiButtonBase-root {
            color: $color-label;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            padding: 10px 16px;
            white-space: nowrap;
            max-width: none;
            background-color: #F9FAFB;

            &:not(:last-child) {
                border-right: 1px solid #D0D5DD;
            }

            &.Mui-selected {
                background-color: rgba($color-primary, 0.1);
            }
        }

        .MuiTabs-indicator {
            display: none;
        }
    }
}

.custom-dialog {
    .MuiPaper-root {
        width: 400px;
        overflow: visible;

        .MuiTypography-root.MuiDialogContentText-root {
            display: flex;
        }

        .react-datepicker-wrapper {
            width: 100%;

            input {
                width: 100%;
                padding: 10px;
                border: 1px solid $color-border;
            }
        }

        .MuiDialogActions-root {
            padding: 0 30px 24px;

            .button-style {
                background-color: $color-primary;
                color: $color-white;
                border-radius: 8px;
                border: 0;
                outline: none;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                &.Mui-disabled{
                    opacity: 0.5;
                cursor: not-allowed;
                pointer-events: all;
                }
            }
        }



    }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: $color-primary !important;
}

.MuiButtonBase-root.hidden {
    display: none !important;
}

.apexcharts-tooltip,
.apexcharts-xaxistooltip-bottom {
    white-space: pre-wrap !important;
    max-width: 200px;
}

.customSelect {
    text-align: left;
    margin-bottom: 20px;

    label {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-label);
        display: block;
        margin-bottom: 5px;
    }

    .MuiInputBase-root {
        border: 1px solid var(--color-border) !important;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
        outline: none !important;
        border-radius: 8px;

        .MuiSelect-select {
            width: 100%;
            padding: 10px 14px;
            font-size: 16px;
            box-shadow: 0 0 0 !important;
            border: 0 !important;
            outline: none !important;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        display: none !important;
    }

}

.MuiAccordion-root {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
    border-radius: 12px !important;
    border: 1px solid $blue6 !important;
    margin-bottom: 15px !important;
    overflow: hidden;

    .MuiAccordionSummary-content {
        margin: 12px 0 !important;

        p {
            font-size: 14px;
            font-weight: 700;
            color: $color-black;
        }
    }

    &:before {
        display: none;
    }
}

.MuiCircularProgress-circle {
    color: $color-primary;
}

.MuiRadio-root {
    color: $color-primary !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &.Mui-checked {
        color: $color-primary !important;
    }

    .MuiSvgIcon-root {
        width: 18px;
    }
}

.successMeasurement {
    text-align: center;

    .success-message {
        width: auto;

        img {
            display: table;
            margin: 0 auto;
        }
    }
}

.gap5 {
    gap: 5px;
}

.welcomeDialog {
    left: 270px !important;
    @include max-screen($tablet) {
        left:0px !important;
    }
    .MuiPaper-root {
        max-width: 400px;
    }

    .MuiBackdrop-root {
        background: rgba(253, 253, 253, 0.26);
        backdrop-filter: blur(8px);
        left: 270px;
        @include max-screen($tablet) {
            left: 0;
        }
    }
}
.text-center{
    text-align: center!important;
}
   
.react-datepicker__header {
    background-color: $color-white !important;
    border: 0!important;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker {
    opacity: 1;
    background-color:$color-white;
    border: 1px solid $color-border!important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 2rem!important;
    line-height: 2rem!important;
    border-radius: 100%!important;
}
.react-datepicker__day--keyboard-selected{
   color: $color-white!important;
}
.noData {
    text-align: center;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
        font-size: 14px;
        color: $color-gray-dark;
    }
}
.react-datepicker__day--keyboard-selected{
    background-color: transparent !important;
    color: $color-black!important;
}
.react-datepicker__day--range-start, 
.react-datepicker__day--keyboard-selected.react-datepicker__day--range-start,
.react-datepicker__day--selecting-range-start{
    background-color: $color-primary !important;
    color: $color-white!important;
}
.calender-error {
    font-size: 14px;
    color: $color-error;
    margin-top: 5px;
}

.graph-line {
    position: relative;
   height: 200px;
    &::after{
        content: "";
        width: calc(100% - 20px);
        background: $color-primary;
        position: absolute;
        bottom: 72px;
        height: 1px;
        right: 10px;
    }
    .graph-date {
        position: absolute;
        bottom: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 20px);
        left: 0;
        right: 0;
        margin: 0 auto;
        span {
            font-size: 10px;
            font-family: Helvetica, Arial, sans-serif;
            opacity: 0.5;
        }
    }
    
}
.apexcharts-text.apexcharts-xaxis-label{
    transform: rotate(0) !important;
    font-size: 10px;
    transform: translateX(15px) !important;
    &:last-child{
       transform: translateX(-15px) !important;
    }
}
.apexcharts-xaxis {
    width: calc(100% - 100px);
}
.tooltip-graph{
    background-color: $color-white-2;
    border-radius: 4px;
    padding: 10px 15px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    span{
        font-size: 12px;
        color: $color-black-5;
    }
}
.graph-lock{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 155px;
    background-color: rgb(89 50 243 / 6%);
    position: absolute;
    z-index: 2;
    top: 10px;
    img{
        width: 30px;
        margin-bottom: 40px;
    }
}
.capsule{
    padding: 2px 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    gap: 6px;
    font-size: 12px;
    font-weight: 500;
    span{
        max-width: 110px;
        text-overflow: ellipsis;  
        overflow:hidden;   
        white-space:nowrap; 
    }
    &.capsuleBorder{
        border: 1px solid;
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.react-pdf__Page__canvas, .textLayer {
    margin: 0 auto;
    width: calc(50vw - 200px) !important;
    height: auto !important;
    @include max-screen($mobile) {
        width: calc(100vw - 150px) !important;
        height: auto !important;
    }
}
.pdfViewerCustom{
    overflow: hidden;
}
.MuiDialog-root{
    z-index: 9999999 !important;
}
.MuiMenu-root{
    z-index: 9999999 !important;
}