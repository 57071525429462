@import '../../assets/scss/variables.scss';
.note{
    background-color: $color-white-3;
    border-radius: 8px;
    padding: 16px;
    font-size: 14px;
    color: $color-gray-dark;
    display: flex;
    align-items: center;
    gap: 15px;
    img{
        max-width: 30px;
    }
}