@import '../../assets/scss/variables.scss';
.MeasurementModal {
    &__close {
        position: absolute;
        right: 7px;
        top: 5px;
        width: 15px;
        cursor: pointer;
        height: 15px;
        transform: scale(0.7);
        opacity: 0.7;
    }

    &__img {
        margin-bottom: 20px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__info {
        h2 {
            font-size: 18px;
            margin: 0 0 5px;
            font-weight: 500;
            color: $color-black;
        }

        h3 {
            font-size: 12px;
            margin: 0 0 5px;
            font-weight: 500;
            color: $color-primary;
        }

        p {
            font-size: 12px;
            margin: 0 0 5px;
            font-weight: 400;
            color: $color-black;
        }
    }

    &__footer {
        border-radius: 4px;
        background: $color-white-5;
        padding: 15px 11px;

        p {
            font-size: 12px;
            margin: 0;
            font-weight: 400;
            color: $color-black;

            span {
                color: $color-primary;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}