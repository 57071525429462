@import '../../assets/scss/variables.scss';

.detailLeft {

    &__header {
        h2 {
            font-size: 28px;
            font-weight: 700;
            font-family: $font-Archivo;
        }
    }

&__boxBorder{
    margin-top: 50px;
}
    &__list {
        margin-top: 40px;

        h4 {
            font-size: 14px;
            font-weight: 700;
            color: $color-black;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: $color-black;
            margin-top: 5px;
         

            a {
                color: $color-black;
                gap: 8px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__top {
        width: 100%;

        img {
            min-width: 100%;
            max-height: 240px;
            object-fit: cover;
        }
    }

    &__excelBox {
        padding: 16px;
        border-radius: 8px;
        border: 1px solid $color-border;
        gap: 16px;
        flex-wrap: wrap;
        margin-top: 20px;

        @include max-screen($desktop) {
            width: 100%;
            flex-wrap: nowrap;
            justify-content: center;


        }

        @include max-screen($small) {
            justify-content: center;
            flex-wrap: wrap;
        }

        figure {
            border: 1px solid $color-border;
            border-radius: 4px;
            padding: 10px;
            min-width: 48px;
        }



        figcaption {
            padding-left: 15px;
            @include max-screen($small) {
                padding: 0;
                text-align: center;
            }
            h5 {
                font-size: 14px;
                font-weight: 500;
                color: $color-label;
            }

            p {
                font-size: 14px;
                color: $color-gray-dark;

                a {
                    display: block;
                    word-break: break-all;
                    width: 100%;
                    color: $color-gray-dark;
                    align-items: center;
                    gap: 5px;
                    position: relative;
                    padding-left: 20px;

                    img {
                        position: absolute;
                        left: 0;
                        top: 3px;
                    }
                }
            }

        }
    }

    &__buttonBottom {
        width: 100%;
        justify-content: center;
        display: flex;

        @include max-screen($desktop) {
            width: auto;

        }
        :global {
            .button {
               height: 40px;
                min-width: 70px;
                padding: 10px;
                border-radius: 8px;

                &.outline {
                    height: 40px;
                    min-width: 100px;
                    padding: 0 5px;
                    border-color: $color-gray-dark;
                    color: $color-gray-dark;
                    font-weight: bold;
                }
            }

        }
    }

    &__excelBoxLeft {
width: 100%;
        @include max-screen($small) {
       flex-direction: column;
       justify-content: center;
       align-items: center;
       gap: 10px;

        }
    }

    &__readMore {
        margin-top: 20px;

        a {
            color: $blue1;
            font-size: 14px;
            font-weight: 600;

            &:hover {
                text-decoration: underline;
            }
        }
    }

  
}
.detailRight{
margin-top: 70px;
    &__noteText {
        margin-top: 48px;

        @include max-screen($desktop) {
            margin-top: 24px;
        }

        h5 {
            font-size: 16px;
            font-weight: 500;
            color: $color-blackLight;
            margin-bottom: 8px;
        }

        p {
            font-size: 16px;
            color: $color-gray-dark;
            margin-bottom: 15px;
        }




    }



    &__table {

        width: 100%;
        overflow-x: auto;
        ::-webkit-scrollbar-thumb:hover{
            background-color:  $color-primary;
          }
table{
    :global{
        .MuiTableBody-root{
            tr{
                &:last-child{
                    th{
                        border-bottom: 0;
                    }
                }
            }
        }
    }

 
}

    }

    &__inner {
        width: 100%;
        display: flex;
        :global{
            .custom-table__inner{
                max-height: 100%;
            }
        }
        margin-bottom: 50px;
    }

    &__customImage {
        margin-top: 32px;
    }


    &__header {
     ul{
        gap: 10px;
        margin-top: 20px;
        padding-left: 20px;
     }
     p{
        color: $color-black-light;
        font-size: 14px;
        font-weight: 600;
        font-style: italic;
     }
    }
    &__action{
        font-size: 14px;
        font-weight: 600;
        color: $color-black-4;
        padding: 8px 12px;
        border-radius: 6px;
        &.active{
            background-color: $blue7;
            color: $color-primary;
        }
    }
    &__latter{
        background-color: $color-white-2;
        border-radius: 3px;
        min-width: 22px;
        min-height: 20px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        padding: 0 6px;
        color: $color-black-light;

    }
    &__tableEdit{
        gap: 10px;
        margin: 15px 0;
    }
    &__hoverBox{
        position: relative;
        &:hover{
            .detailRight__hoverBoxList{
                display: block;
            }
        }
    }
  &__textBox{
    margin-top: 50px;
    textarea{
        padding: 12px;
        border: 1px solid $blue9;
        border-radius: 8px;
        resize: none;
        width: 100%;
        height: 108px;
        font-family: $font-primary;
        font-size: 16px;
        color:$color-input;
        box-shadow: 0px 0px 0px 4px #F4EBFF ;
    }
    p{
        margin-top: 5px;
        font-size: 14px;
        color: $color-gray-dark;
    }
  }
  &__boxBorder{
    margin: 100px 0;
    position: relative;
    &::after{
        content: "";
        width: calc(100% + 200px);
        height: calc(100% + 100px);
        box-shadow: 0px 0px 4px rgb(0 0 0 / 7%);
        border-radius: 10px;
        position: absolute;
        left: -100px;
        top: -50px;
        pointer-events: none;
        @include max-screen($tablet) {
            left:-10px;
        top:-20px;
        width: calc(100% + 20px);
        height: calc(100% + 40px);
        }
    }
  }
    &__hoverBoxList{
        box-shadow: 0px 1px 4px 0 rgba(#0C0C0D0D, 5%), 0px 16px 32px -4px rgba(#0C0C0D0D, 10%) ;
        background-color: $color-white;
        width: 202px;
        padding: 10px 14px;
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 22px;
        z-index: 9;
        ul{
            padding: 0;
            margin: 0;
            li{
                display: flex;
                gap: 10px;
                font-size: 12px;
                font-weight: 500;
                color: $color-black;
                &:not(:last-child){
                    margin-bottom: 10px;
                }
            }
        }
    }
    &__noteEdit{
        margin-top: 15px;
        textarea{
            width: 100%;
            border: 1px solid $color-gray;
            border-radius: 8px;
            min-height: 44px;
            padding: 10px 14px;
            font-size: 16px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            font-family: $font-primary;
        }
    }
}