@import 'assets/scss/variables.scss';

.cardList {
    margin: 24px -12px;

    .card {
        width: 33.3%;
            padding: 0 15px;
            margin-bottom: 120px;
            @include max-screen($tablet) {
                width: 50%;
                margin-bottom: 30px;
            }
            @include max-screen($mobile) {
                width: 100%;
                margin-bottom: 20px;
            }

        :global .noData {
            padding: 0;
            min-height: 120px;
        }
    }
}