@import '../../assets/scss/variables.scss';

.measurement {
    margin: 0px 15px 80px;

    @include max-screen($tablet) {
        margin: 15px;
    }

    &__back {
        margin: 15px 0 80px;

        @include max-screen($tablet) {
            margin: 15px 0;
        }
    }
    &__inner {
        gap: 35px;
        max-width: 1036px;
        margin: 0 auto;
    }
    &__tag{
        position: absolute;
        top: 0;
    }
    &__box {
        width: calc(33.3% - 24px);
        border: 1px solid $color-white-2;
        border-radius: 4px;
        overflow: hidden;
        flex-direction: column;
        display: flex;

        @include max-screen($tablet) {
            width: calc(50% - 19px);
        }

        @include max-screen($mobile) {
            width: 100%;
            max-width: 312px;
            margin: 0 auto;
        }

        figure {
            max-height: 312px;
            overflow: hidden;
            padding-top: 100%;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    &__capsule{
        height: 312px;
        gap: 10px;
        padding: 15px;
        border-bottom: 1px solid $color-white-2;
        flex: 1;
    }

    &__heading {
        padding: 25px 22px;
        h2 {
            font-size: 20px;
            font-weight: 500;
            color: $color-black;
            font-family: $font-Archivo;
        }
    }

    &__link {
        color: $color-primary;
        background-color: $blue12;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 30px;
        border-radius: 55px;
        padding: 4px;
        span {
            font-size: 12px;
            font-weight: 500;
            justify-content: center;
            display: flex;
            align-items: center;
            gap: 6px;
            padding: 2px 8px;
            background-color: $blue7;
            border-radius: 55px;
        }
    }
    &__tags {
        padding: 0 20px 15px;
        span {
            margin: 2px;
            font-size: 10px;
        }
    }
}

.measurementModal {
    padding: 15px 0 0 0;
    pointer-events: all;
&__close{
    position: absolute;
    right: 15px;
    top: 15px;
}
    &__header {
        text-align: center;

        h2 {
            font-size: 16px;
            font-weight: 600;
            color: $color-black-dark;
        }

        p {
            margin-top: 10px;
            font-size: 14px;
            color: $color-gray-dark;
        }
    }

    &__info {
        margin-top: 38px;
        max-width: 497px;
        margin: 38px auto 25px;
        gap: 17px;
    }

    &__infoLeft {
        width: calc(50% - 8.5px);
        border: 2px solid rgb($color-primary, 50%);
        border-radius: 15px;
        padding: 16px;

        @include max-screen($mobile) {
            width: 100%;
        }

        h5 {
            font-size: 14px;
            font-weight: 500;
        }

        p {
            margin-top: 15px;
            font-size: 12px;
            color: $color-gray-dark;
        }
    }

    &__infoRight {
        width: calc(50% - 8.5px);
        background-color: rgb(241, 240, 239, 25%);
        padding: 16px;
        border-radius: 2px;

        @include max-screen($mobile) {
            width: 100%;
        }
    }

    &__infoRightText {
        p {
            font-size: 12px;
            font-weight: 500;
            color: $color-label;
            margin: 5px 0;
        }

        span {
            color: $color-primary;
            font-size: 12px;
            font-weight: 600;
            width: 100%;
        }
    }

    &__button {
        button {
            max-width: 123px;
        }
    }
}
.uploadFileInfo{
    display: flex;
    padding: 8px 10px;
    border-radius: 5px;
    margin: 6px 0;
    font-size: 12px;
    font-weight: 500;
    align-items: center;
    justify-content: space-between;
    color: $color-black-dark;
    background-color: rgba($color-gray-light, 0.3);
    border: 1px solid $color-gray-light;
    span {
        text-align: left;
        color: $color-gray-dark !important;
        width: 170px !important;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: default;
    }
    svg {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
}