@import 'assets/scss/variables.scss';

.login {
    min-height: 100vh;

    @include max-screen($tablet) {
        flex-direction: column;
    }
    &__logo{
padding: 20px 25px 0;
display: flex;
align-items: flex-start;
width: 100%;
@include max-screen($tablet) {
  padding: 15px 
}
    }
    &__appInstalled{
        text-align: center;
        margin-bottom: 27px;
        img{
            display: table;
            margin: 0 auto;
        }
        h2{
            margin: 0;
            color: $color-primary;
            font-family: $font-SeriouslyNostalgic;
            font-size: 36px;
            font-weight: normal;
        }
    }
    &__left {
        width: 50%;
        flex-direction: column;
        display: flex;

        @include max-screen($tablet) {
            width: 100%;
        }

    }

    &__signUp {
        text-align: left;
        margin-bottom: 32px !important;
        font-size: 36px !important;
    }

    &__auth {
        align-items: center;
        display: flex;
       flex: 1;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
        max-width: 390px;
        padding: 0 15px;
        text-align: left;
        width: 100%;
        height: 100%;
        padding-bottom: 82px;
        form {
            width: 100%;

            img {
                margin: 0 auto;
                width: 120px;
            }

            h2 {
                font-size: 30px;
                font-weight: 600;
                margin-bottom: 5px;
                margin-top: 15px;

            }

            p {
                color: $color-gray-dark;
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 30px;
            }
        }

    }

    &__icon {
        // border-radius: 28px;
        // border: 10px solid $color-primary-light;
        // background: $color-white-9;
        // display: flex;
        // width: 56px;
        // height: 56px;
        // padding: 8px;
        // justify-content: center;
        // align-items: center;
        // margin: 0 auto 14px;
        display: flex;
        justify-content: flex-start;
    }
&__heading{
    h2{
        font-family: $font-SeriouslyNostalgic;
        font-weight: normal !important;
        font-size: 36px!important;
        padding-bottom: 30px;
        color: $color-primary;
        margin: 0 !important;

    }
   
}
    &__message {
        max-width: 400px;
        margin: 0px auto;
        text-align: center;

        h2 {
            font-size: 26px;
            font-weight: 500;
            margin-bottom: 10px;
            margin-top:18px;
            text-align: left;
            color: $color-label;
        }

        p {
            font-size: 14px;
            color: $color-gray-dark;
            margin-bottom: 30px;
            font-weight: 600;
            text-align: left;
        }

    }

    &__back {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-size: 14px !important;
        font-weight: 600 !important;
        margin-bottom: 0 !important;
        cursor: pointer;
    }

    &__resend {
       
        margin-top: 57px;
        text-align: center !important;

        span {
            font-weight: 500;
            color: $color-primary;
            font-size: 14px !important;
            cursor: pointer;
        }
    }

    &__button {
        background-color: $color-primary;
        color: $color-white;
        border-radius: 4px;
        border: 0;
        outline: none;
        height: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        gap: 0 5px;
        cursor: pointer;
        padding: 10px 12px;
        width: 100%;
    }

    &__formGroup {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // margin-bottom: 20px;

        label {
            color: $color-black-light;
            display: block;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 5px;
            text-align: left;
        }

        input {
            width: 100%;
            border: 1px solid $color-border;
            border-radius: 8px;
            min-height: 44px;
            padding: 10px 14px;
            font-size: 16px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

            &::placeholder {
                color: $color-gray-6;
                opacity: 1;
            }
        }

        .error {
            color: $color-error;
            font-size: 14px;
            margin-top: 2px;
        }

        button {
            margin: 8px 0 10px;
        }
    }

    .already {
        width: 100%;

        p {
            color: $color-gray-dark;
            font-size: 14px;
            text-align: center;
            margin: 0;

            span {
                color: $color-primary;
                cursor: pointer;
                font-weight: 500;
            }
        }

    }

    &__loginHeader {
        text-align: center;
        padding-top: 15px;
    }

    &__apiError {
        font-size: 16px;
        text-align: center;
        background-color: $color-error-2;
        border-radius: 55px;
        padding: 5px 10px;
        color: $color-error;
        border: 1px solid $color-error;
        margin: 15px 0;

        &.green {
            background-color: $color-green-light;
            color: $color-green;
            border: 1px solid $color-green;
        }
    }

    &__disable {
        cursor: not-allowed !important;
        color: $color-blue-4 !important;
    }

    &__leftFooter {
        padding: 32px;

        @include max-screen($tablet) {
            padding: 15px;
        }

        p {
            color: $color-gray-dark;
            font-size: 14px;

            a {
                color: $color-gray-dark;
                font-size: 14px;
                display: flex;
                align-items: center;
                gap: 5px;
            }

        }
    }

    &__right {
        width: 50%;
        display: flex;
        align-items: center;
        overflow-x: hidden;
        justify-content: center;
        padding: 32px;
        background: linear-gradient(180deg, rgba(100, 50, 243, 1) 0%, rgba(42, 10, 169, 1) 100%);
        flex-direction: column;
        display: flex;

        @include max-screen($tablet) {
            width: 100%;
            padding: 15px;
        }
    }

    &__rightSlider {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    &__rightFooter {
        width: 100%;
        padding: 15px 0;

        ul {
            display: flex;
            width: 100%;
            justify-content: space-between;
            gap: 13px;

            @include max-screen($tablet) {
                text-align: center;
                gap: 10px;
            }

            @include max-screen($tablet) {
                flex-direction: column;
                align-items: center;
                gap: 5px;
            }

            li {
                @include max-screen($tablet) {
                    width: 100%;
                    text-align: center;
                }

                span {
                    color: $color-white;
                    font-family: $font-SeriouslyNostalgic;
                    font-size: 24px;
                    line-height: 1.2;

                    @include max-screen($tablet) {
                        font-size: 20px;
                    }


                }
            }
        }
    }
}