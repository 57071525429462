@import '../../assets/scss/variables.scss';

.list {
    padding: 40px;
    width: 100%;
    position: relative;
    height: 100vh;
    @include max-screen($mobile) {
        justify-content: flex-start;
    }
    @include max-screen($small) {
        padding: 15px;
    }
&__formBtn{
    button{
        margin: 0 !important;
    }
}
    &__form {
        margin-bottom: 20px;

        h2 {
            color: $color-black;
            font-size: 24px;

            span {
                color: $color-primary;
            }
        }

        input {
            max-width: 350px;
            width: 100%;
            height: 40px;
            border-radius: 6px;
            border: 1px solid $color-border;
            padding: 10px;
            font-size: 16px;

            @include max-screen($small) {
                max-width: 200px;
            }

            &:focus {
                border-color: $color-black;
            }
        }
    }

    &__outer {
        min-height: calc(100vh - 196px);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        &:hover::-webkit-scrollbar-thumb{
            background-color:  $blue3;
          }
        @include max-screen($mobile) {
            min-height: 0;
            max-height: calc(100vh - 100px);
        }
    }

    &__wrap {
        flex-wrap: wrap;
        row-gap: 15px;
        overflow-y: auto;
        height: 100%;
        border: 1px solid $color-border;
        padding: 20px;
        border-radius: 6px;
        &:hover::-webkit-scrollbar-thumb{
            background-color:  $blue3;
          }
        @include max-screen($small) {
            padding: 10px 0;
        }
    }

    &__item {
        padding: 0 10px;
        flex: 33%;
        max-width: 33.33%;
        padding: 0 10px;

        @include max-screen($tablet) {
            flex: 50%;
            max-width: 50%;
        }

        @include max-screen($mobile) {
            flex: 100%;
            max-width: 100%;
        }

        aside {
            padding: 30px 20px;
            background-color: $color-white;
            border-radius: 6px;
            border: 1px solid $color-border;
            min-height: 100%;

            position: relative;

            &.active {
                border: 1px solid $color-primary;
            }

            &.active::after {
                background-image: url('../../assets/images/check-tick.svg');
                background-repeat: no-repeat;
                content: "";
                position: absolute;
                background-size: 20px;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                padding: 4px;
                background-position: center;
                right: 10px;
                top: 10px;
                border: 1px solid $color-primary;
            }

            .appNotInstalled {
                font-size: 12px;
                color: $color-warning;
                font-weight: 500;
                padding: 2px 8px;
                border-radius: 16px;
                display: table;
                margin-top: 10px;
                white-space: nowrap;
                background-color: rgb($color-warning, 0.1);
                &.green{
                    background-color: $successBg;
                    color: $success;
                }
                &.blue{
                    color: $color-blue-2;
                    background-color: rgba($color-blue-2, 0.1);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    cursor: pointer;
                    margin-top: 15px;
                    display: inline-flex;
                    align-items: center;
                    svg{
                        width: 20px;
                    }
                }
            }

            .listSwitch {
                gap: 10px;
                margin-bottom: 10px;
                color: $color-inputDark;
            }
        }

        h2 {
            font-size: 20px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .storeUrl {
            font-size: 18px;
            color: $color-primary;
            word-break: break-all;
        }
    }

    button {
        margin-top: 20px;
        min-width: 100px;
        margin-left: auto;
        height: 35px;
        border-radius: 4px;
        font-size: 14px;
    }

    .isDisabled {
        pointer-events: none;

    }

    &__button {
        padding-bottom: 20px;
    }
    &__editLink{
        background-color: $color-primary;
        @include round-image(24px);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        padding: 2px;
        svg{
            width: 16px;
            height: 16px;
        }
    }
}