@import '../../assets/scss/variables.scss';
.quickCard{
    border: 1px solid $color-white-2;
    border-radius: 4px;
    height: 100%;
    &__links{
        gap: 12px;
        margin-top: 38px;
    }
    &__linksCol{
        width: 100%;
       
    }
    figure{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $blue11;
        padding-top: 100%;
        position: relative;
        overflow: hidden;
        img{
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
    figcaption{
        padding: 24px 20px;
        flex: 1;
        @include max-screen($tablet) {
            padding:15px;
             }
        h2{
            font-size: 20px;
            font-weight: 600;
        }
        p{
            margin-top: 14px;
            font-size: 12px;
            color: $color-gray-dark;
        }
    }
    &__button{
        border-radius: 16px;
        margin-top: 15px;
        display: inline-flex;
        background-color: $blue12;
        padding: 6px 12px;
        font-size: 12px;
        font-weight: 500;
        gap: 16px;
        min-width: 66px;
        text-align: center;
        width: fit-content;
        color: $color-black;
        span{
            color: $color-primary;
            display: inline-flex;
            align-items: center;
            gap: 5px;
        }
    }
    &__info{
        flex: 1;
    }
}