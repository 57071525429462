@import '../../assets/scss/variables.scss';
.home{
    max-width: 653px;
    margin: 0 auto;
    padding: 106px 15px;
    height: 100%;
    @include max-screen($mobile) {
        padding: 30px 15px;
    }
    &__wrap{
        padding-bottom: 30px;
    }
    h2{
        font-size: 20px;
        font-weight: 500;
        font-family: $font-Archivo;
    }
    &__inner{
        margin: 20px 0 100px 0;
        @include max-screen($mobile) {
            margin: 20px 0;
        }
    }
}
.table{
    max-width: 823px;
    margin: 30px auto;
}