@import 'assets/scss/variables.scss';

.cardList {
    margin: 24px -12px;

    .card {
        width: 33.3%;
        padding: 0 12px;
        margin-bottom: 24px;

        @include max-screen($tablet) {
            width: 100%;
            margin-bottom: 24px;
        }

        :global .noData {
            padding: 0;
            margin-bottom: 30px;
        }
    }
}