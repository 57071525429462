@import '../../assets/scss/variables.scss';
.back{
    font-size: 12px;
    color: $color-black;
    img{
        max-width: 16px;
    }
    &:hover{
        color: $color-black;
    }
}
