@import '../../assets/scss/variables.scss';
.measurementCard{
    width: calc(50% - 6.5px);
        @include max-screen($mobile) {
            width: 100%;
        }
        input[type="radio"]{
            opacity: 0;
         position: absolute;
         &:checked{
          &+{
            .measurementCard__box{
                border: 2px solid rgba($color-primary, 50%); 
                .chip{
                    background-color: $color-primary;
                }
                .checkCircle{
                    opacity: 1;
                }
            }
            
          }
         }
        }

    .checkCircle{
        opacity: 0;
        position: absolute;
        top: 10px;
        right: 10px;
    }
    &__btn{
        margin-top: 15px;
        span{
            font-size: 12px;
            color: $color-primary;
            text-decoration: underline;
        }
    }
    .chip{
        background-color: $color-gray-6;
        font-size: 14px;
        font-weight: 500;
        border-radius: 16px;
        min-height: 24px;
        min-width: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
    }
    &__box{
        border-radius: 10px;
        border: 2px solid rgba(196, 196 , 196, 50%); 
        padding: 21px 16px 17px;
        min-height: 264px;
      height: 100%;
      position: relative;
        @include max-screen($tablet) {
            padding: 15px;
        }
        h5{
            font-size: 14px;
            font-weight: 500;
            margin-top: 16px;
        }
        p{
            margin-top: 16px;
            font-size: 12px;
            color: $color-gray-dark;
        }
    }
    &__boxLink{
        color: $blue5;
        font-size: 12px;
    }
    &__boxInfo{
        flex: 1;
    }
    &__radio{
        margin-top: 58px;
        @include max-screen($tablet) {
            margin-top: 15px;
        }
        h5{
            font-size: 14px;
            font-weight: 500;
            color: $color-black-light;
        }
        ul{
            margin-top: 23px;
            li{
                &:not(:first-child){
                    margin-top: 16px;
                }
            }
        }
    }
  
}