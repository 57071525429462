@import 'assets/scss/variables.scss';
.input{
        margin-bottom: 20px;
        width: 100%;
    &.singleInput{
        margin-bottom: 0;
    }
    &.smallInput{
        margin-bottom:10px;
        label{
            font-size: 12px;
        } 
        input, textarea{
            border: 1px solid rgb($color-border, 50%);
            min-height: 33px;
           padding:  6px 14px;
           font-size: 12px;
           color: $color-gray-dark;
        }
        textarea{
            height: 180px;
            resize: none;
          
        }
    }
   label{
        font-size: 14px;
        font-weight: 500;
        color: var(--color-label);
        display: block;
        margin-bottom: 6px;
   }
   input, textarea{
    width: 100%;
    border: 1px solid $color-border;
    border-radius: 8px;
    min-height: 44px;
    padding: 10px 14px;
    font-size: 16px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    &::placeholder{
        color: var(--color-input);
    }
   } 
   textarea{
    height: 102px;
    font-family: $font-primary !important;
   }
   .belowText{
    font-size: 14px;
        font-style: normal;
        font-weight: 400;
       color: $color-gray;
       margin-top: 5px;
       width: 100%;
   }
   .error{
    color: $color-red;
    font-size: 14px;
    margin-top: 2px;
   }
  
}
.inputWrapper{
    width: 100%;
    position: relative;
}
    .prLabelWrapper{
        width: 100%;
        border: 1px solid var(--color-border);
        border-radius: 8px;
        min-height: 44px;
        padding: 0px 14px;
        font-size: 16px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        display: flex;
        align-items: center;
        position: relative;
        .prLabel{
            border-right: 1px solid var(--color-border);
            display: inline-flex;
            padding: 10px 12px 10px 0;
            margin-right: 14px;
            font-size: 16px;
            color: $color-gray;
            font-weight: 400;
        }
        input{
            border: 0;
            box-shadow: 0 0 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
       
    }
    .afterIcon{
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
    .checkbox label{
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }
.checkbox input {
    position: absolute;
    width: 0;
    left: 50px;
    height: 0;
    opacity: 0;
    cursor: pointer;
}

.checkbox .checkmark {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 3px;
    outline: 1px solid #acacac;
    transition: all 0.2s ease;
}

.checkbox:hover .checkmark {
    background: #f4f4f5;
    transition: all 0.2s ease;
}

.checkbox input:checked ~.checkmark {
    background: $color-primary;
    outline: 1px solid $color-primary;
}

.checkbox input[type="radio"] ~ .checkmark {
    border-radius: 50%;
}

.checkbox .checkmark::after {
    position: absolute;
    display: block;
    content: "";
    left: 50%;
    top: 40%;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: all 0.2s ease;
}

.checkbox input:checked~.checkmark::after {
    opacity: 1;
    transition: all 0.2s ease;
}