
.MuiPaper-root {
    margin: 15px !important;
    width: 100% !important;
    padding: 15px !important;
    border-radius: 10px  !important;
    &:hover::-webkit-scrollbar-thumb{
        background-color:  $color-primary;
      }
    .invite-dialog &{
        max-width: 700px;
        pointer-events: none;
    }
    .registerModal &{
        overflow-y: auto;
    }
    .desc-modal &{
        width: 100%!important;
        max-width: 314px!important;
        border-radius: 8px!important;
        box-shadow: 0px 5px 15px 0px rgba(129, 129, 129, 0.14)!important;
        padding: 20px 20px 15px !important;
        position: relative;
    }
    .disabled-overlay &{
        pointer-events: none;
    }
    .measurement-modal &{
        max-width: 700px;
    }
    .poweredSalesModal &{
        max-width: 430px;
    }
    &.MuiMenu-paper{
        margin: 0 !important;
        width: auto !important;
        min-width: 150px;
        border-radius: 4px  !important;
        padding: 0 !important;
        ul{
            li{
                font-family: $font-primary;
                font-size: 14px;
                display: flex;
                gap: 8px;
            }
        }
    }
}
.invite-dialog,  .disabled-overlay{
    pointer-events: none;
}
.drawer-content{
    padding: 15px 15px 40px 15px;
    width: 100%;
   
    height: 100%;
  position: relative;
  &__scroll{
    flex: 1;
    overflow: auto;
    max-height: calc(100vh - 215px);;
    position: relative;
    &:hover::-webkit-scrollbar-thumb{
        background-color:  $blue3;
      }
  }
    &__header{
        margin-bottom: 15px;
        p{
            font-size: 16px;
        }
    }
    &__nav{
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $color-white;
        width: 100%;
        padding: 8px 15px;
    
        button{
            border: 1px solid $color-primary;
            padding: 3px 5px;
            background-color: $color-primary;
            color: $color-white;
            border-radius: 4px;
            font-weight: 500;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                width: 15px;
                height: 15px;
            }
            &:disabled{
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
        p{
            background-color: $color-white;
        }
    }
    p{
        font-size: 10px!important;
    color: $color-gray-dark!important;
    margin: 0 !important;
    }
    table{
        width: 100%;
        border-collapse: collapse;
        tr{
            th{
            padding: 5px;
            font-size: 12px;
            font-weight: 500;
            color: $color-green-5;
            text-align: left;
            border: 1px solid $color-gray;
            white-space: nowrap;
            }
            td{
                font-size: 12px;
                padding: 5px;
                color: $color-black;
                border: 1px solid $color-gray;
            }
        }
    }
}
.react-pdf__Page__canvas {
    margin: 0 auto;
  }
